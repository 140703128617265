import ensureError from '@studio/utils/ensureError';
import { parseEnvironmentConfigFromHostname } from '@studio/utils/fromEnvironment';
import { promiseProps } from '@studio/utils/promise';
import type { RequestContext } from '@studio/utils/requestContext';

import { HttpError } from '../errors';
import { type DataDocument, type Inlined, inlineDocument } from '../json-api';
import type {
  LocalizedFieldSetting,
  LocalizedFieldSettingOwningType,
} from '../localization';
import usersApi from '../users';
import { apiURL, fetchWrapper } from '../utils';
import type {
  AppName,
  Brand,
  ContentRestrictionLevel,
  ContentSubset,
  LanguageTag,
  Platform,
  PlaybackSettings,
  ProductLine,
  PublishingSite,
  Settings,
  Site,
  Territory,
  TerritoryGroup,
  TerritoryGroupKind,
} from './types';

export * from './types';

export function gracefulFailPromise<T>(
  promise: T | PromiseLike<T>,
  serviceName: string,
): Promise<T | undefined> {
  return Promise.resolve(promise).catch(error => {
    if (error instanceof HttpError) {
      // If service is not available. Gracefully pretend it is and return resolved promise
      console.warn('Service not available', serviceName);
      return undefined;
    }

    throw error;
  });
}

function gracefulFail<T>(error: Error, fallback: T): T {
  if (error instanceof HttpError) {
    // If service is not available. Gracefully pretend it is and return empty array
    console.warn('Service not available in realm', error);
    return fallback;
  }

  throw error;
}

const settingsApi = {
  getSettings(context: RequestContext): Promise<Settings> {
    return promiseProps({
      studioConfig: usersApi.getStudioConfig(context),
      umsUiConfig: usersApi.getUmsUiConfig(context),
      environment: parseEnvironmentConfigFromHostname(),
    });
  },

  async getSites(context: RequestContext): Promise<readonly Inlined<Site>[]> {
    const url = apiURL`/admin/settings/sites`;

    try {
      const response = await fetchWrapper(url, context);

      const document: DataDocument<Site[]> = await response.json();

      return inlineDocument(document);
    } catch (error) {
      return gracefulFail(ensureError(error), []);
    }
  },

  async getSite(
    context: RequestContext,
    siteId: string,
  ): Promise<Inlined<Site> | null> {
    const url = apiURL`/admin/settings/sites/${siteId}`;

    try {
      const response = await fetchWrapper(url, context);

      const document: DataDocument<Site> = await response.json();

      return inlineDocument(document);
    } catch (error) {
      if (error instanceof HttpError && error.status === 404) {
        return null;
      }

      throw error;
    }
  },

  async getLocalizedFieldSettings(
    context: RequestContext,
    owningType?: LocalizedFieldSettingOwningType | null,
  ): Promise<readonly Inlined<LocalizedFieldSetting>[]> {
    const url = apiURL`/admin/settings/localizedFieldSettings`;

    if (owningType != null) {
      url.searchParams.set('filter[owningType]', owningType);
    }

    try {
      const response = await fetchWrapper(url, context);

      const document: DataDocument<LocalizedFieldSetting[]> =
        await response.json();

      return inlineDocument(document);
    } catch (error) {
      return gracefulFail(ensureError(error), []);
    }
  },

  async getPublishingSites(
    context: RequestContext,
    includes: ('sites' | 'contentSubsets')[] = ['sites', 'contentSubsets'],
  ): Promise<readonly Inlined<PublishingSite>[]> {
    const url = apiURL`/admin/settings/publishingSites`;

    url.searchParams.set('include', includes.join(','));

    try {
      const response = await fetchWrapper(url, context);

      const document: DataDocument<PublishingSite[]> = await response.json();

      return inlineDocument(document);
    } catch (error) {
      return gracefulFail(ensureError(error), []);
    }
  },

  async getContentSubsets(
    context: RequestContext,
  ): Promise<readonly Inlined<ContentSubset>[]> {
    const url = apiURL`/admin/settings/contentSubsets`;

    const response = await fetchWrapper(url, context);

    const document: DataDocument<ContentSubset[]> = await response.json();

    return inlineDocument(document);
  },

  async getTerritoryGroupsByKind(
    context: RequestContext,
    kind: TerritoryGroupKind,
  ): Promise<readonly Inlined<TerritoryGroup>[]> {
    const url = apiURL`/admin/settings/territoryGroups`;

    url.searchParams.set('filter[territoryGroupKind]', kind);
    url.searchParams.set('include', 'territory');

    try {
      const response = await fetchWrapper(url, context);

      const document: DataDocument<TerritoryGroup[]> = await response.json();

      return inlineDocument(document);
    } catch (error) {
      return gracefulFail(ensureError(error), []);
    }
  },

  async getTerritories(
    context: RequestContext,
  ): Promise<readonly Inlined<Territory>[]> {
    const url = apiURL`/admin/settings/territories`;

    try {
      const response = await fetchWrapper(url, context);

      const document: DataDocument<Territory[]> = await response.json();

      return inlineDocument(document);
    } catch (error) {
      return gracefulFail(ensureError(error), []);
    }
  },

  async getLanguageTags(
    context: RequestContext,
  ): Promise<readonly Inlined<LanguageTag>[]> {
    const url = apiURL`/admin/settings/languageTags`;

    try {
      const response = await fetchWrapper(url, context);

      const document: DataDocument<LanguageTag[]> = await response.json();

      return inlineDocument(document);
    } catch (error) {
      return gracefulFail(ensureError(error), []);
    }
  },

  async getPlatforms(
    context: RequestContext,
  ): Promise<readonly Inlined<Platform>[]> {
    const url = apiURL`/admin/settings/platforms`;

    const response = await fetchWrapper(url, context);

    const document: DataDocument<Platform[]> = await response.json();

    return inlineDocument(document);
  },

  async getAppNames(
    context: RequestContext,
  ): Promise<readonly Inlined<AppName>[]> {
    const url = apiURL`/admin/settings/appNames`;

    const response = await fetchWrapper(url, context);

    const document: DataDocument<AppName[]> = await response.json();

    return inlineDocument(document);
  },

  async getPlaybackSettings(
    context: RequestContext,
  ): Promise<Inlined<PlaybackSettings>> {
    const url = apiURL`/admin/settings/playbackSettings`;

    const response = await fetchWrapper(url, context);

    const document: DataDocument<PlaybackSettings> = await response.json();

    return inlineDocument(document);
  },

  async getBrands(context: RequestContext): Promise<readonly Inlined<Brand>[]> {
    const url = apiURL`/admin/settings/brands`;
    const response = await fetchWrapper(url, context);
    const document: DataDocument<Brand[]> = await response.json();
    return inlineDocument(document);
  },

  async getContentRestrictionLevels(
    context: RequestContext,
  ): Promise<readonly Inlined<ContentRestrictionLevel>[]> {
    const url = apiURL`/admin/settings/contentRestrictionLevels`;
    const response = await fetchWrapper(url, context);
    const document: DataDocument<ContentRestrictionLevel[]> =
      await response.json();

    return inlineDocument(document);
  },

  async getProductLines(
    context: RequestContext,
  ): Promise<readonly Inlined<ProductLine>[]> {
    const url = apiURL`/admin/settings/productLines`;
    const response = await fetchWrapper(url, context);
    const document: DataDocument<ProductLine[]> = await response.json();

    return inlineDocument(document);
  },
};

export default settingsApi;
