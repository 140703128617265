import type { Location } from 'history';
import { useDebugValue, useEffect, useState } from 'react';

import { setCustomUserConfirmation } from './hybridHistory';
import useHybridHistory from './useHybridHistory';

export type Blocker =
  | {
      state: 'unblocked';
      reset: undefined;
      proceed: undefined;
    }
  | {
      state: 'blocked';
      reset(): void;
      proceed(): void;
    };

const UNBLOCKED_BLOCKER: Blocker = {
  state: 'unblocked',
  reset: undefined,
  proceed: undefined,
};

const TRIGGER_USER_CONFIRMATION = '';

export default function useBlocker(
  shouldBlock: boolean,
  options?: {
    shouldBlockBasedOnLocation?: (location: Location<unknown>) => boolean;
  },
): Blocker {
  useDebugValue(shouldBlock);

  const history = useHybridHistory();
  const [blocker, setBlocker] = useState<Blocker>(UNBLOCKED_BLOCKER);

  useEffect(() => {
    if (!shouldBlock) {
      setBlocker(UNBLOCKED_BLOCKER);
      return undefined;
    }

    const unblock = history.block(location => {
      if (
        options?.shouldBlockBasedOnLocation != null &&
        !options.shouldBlockBasedOnLocation(location)
      ) {
        return;
      }

      return TRIGGER_USER_CONFIRMATION;
    });

    setCustomUserConfirmation((_message, callback) => {
      setBlocker({
        state: 'blocked',
        reset() {
          callback(false);
          setBlocker(UNBLOCKED_BLOCKER);
        },
        proceed() {
          callback(true);
          setBlocker(UNBLOCKED_BLOCKER);
        },
      });
    });

    return () => {
      setCustomUserConfirmation(undefined);
      unblock();
    };
  }, [history, options, shouldBlock]);

  return blocker;
}
