export type { Market, Subdivision, Tenant } from '../utils/subdivision';

export interface Preferences {
  readonly timeZone: TimeZonePreference;
  readonly region: RegionPreference;
  readonly table: TablePreference;
  readonly infoPanel: InfoPanelPreference;
}

export interface TablePreference {
  readonly rowsPerPage: number;
}

export type TimeZonePreference =
  | { readonly type: 'realm' }
  | { readonly type: 'local' }
  | { readonly type: 'custom'; readonly timeZone: string };

export interface RegionPreference {
  readonly region: string;
}

export enum RecentlyViewedEntryType {
  Unknown = 'unknown',
  // Videos
  Video = 'video',
  Show = 'show',
  Channel = 'channel',
  Livestream = 'livestream',
  Airing = 'airing',
  DistributionChannel = 'distributionChannel',

  // Site builder
  Collection = 'collection',
  Page = 'page',
  Article = 'article',
  Avatar = 'avatar',
  Image = 'image',
  Link = 'link',
  RawContent = 'rawContent',
  SiteBuilderExperiment = 'siteBuilderExperiment',
  SiteBuilderFeature = 'siteBuilderFeature',

  // Monetization
  PricePlan = 'pricePlan',
  Plan = 'plan',
  Campaign = 'campaign',
  Product = 'product',
  ProductTransition = 'productTransition',
  Package = 'package',
  Contract = 'contract',
  Affiliate = 'affiliate',
  MarketPrice = 'marketPrice',

  // Users
  User = 'user',

  // Labs
  Experiment = 'experiment',
  FeatureFlag = 'featureFlag',

  // Subscription journey
  MarketingPage = 'marketingPage',
  MarketingJourney = 'marketingJourney',
  MarketingRoute = 'marketingRoute',
  MarketingCollectionGroup = 'marketingCollectionGroup',
  MarketingCollection = 'marketingCollection',
  MarketingExperiment = 'marketingExperiment',

  // Comms
  EmailTemplate = 'emailTemplate',

  // Settings
  Site = 'site',
  PublishingSite = 'publishingSite',
  Brand = 'brand',
  ContentRestriction = 'contentRestriction',
  Territory = 'territory',
  TerritoryGroup = 'territoryGroup',
  ContentSubset = 'contentSubset',
  ProductLine = 'productLine',

  // Capabilities
  CapabilityDefinition = 'capabilityDefinition',
  ContentSegment = 'contentSegment',
  CapabilityPackage = 'capabilityPackage',

  // PPV
  PriceLevel = 'priceLevel',
  PriceLevelProposal = 'priceLevelProposal',
  OfferDefinition = 'offerDefinition',
  OfferDefinitionProposal = 'offerDefinitionProposal',
  Offer = 'offer',
}

export interface RecentlyViewedEntry {
  readonly type: RecentlyViewedEntryType;
  readonly id: string;
  readonly title: string;
  readonly timestamp: string;
  readonly studioExperience?: string;
}

export interface RecentlyViewed {
  readonly entries: readonly RecentlyViewedEntry[];
}

export enum SavedItemType {
  ContentView = 'contentView',
  Program = 'program',
  Series = 'series',
  Season = 'season',
}

export interface SavedItemView {
  readonly type: SavedItemType.ContentView;
  readonly query: string;
  readonly label: string;
  readonly pinned: boolean;
}

export interface SavedItemResource {
  readonly type:
    | SavedItemType.Program
    | SavedItemType.Series
    | SavedItemType.Season;
  readonly id: string;
  readonly label: string;
  readonly pinned: boolean;
}

export type SavedItem = SavedItemView | SavedItemResource;

export interface SavedItems {
  readonly savedItems: readonly SavedItem[];
}

export interface InfoPanelPreference {
  readonly collapsed: boolean;
}

export interface CmsBookmarksV1 {
  readonly version: 1;
  readonly bookmarks: Readonly<
    Record<string, Readonly<Record<string, boolean>>>
  >;
}

export interface CmsBookmarkV2 {
  readonly id: string;
  readonly type: string;
  readonly publishingSiteId: string | null;
}

export interface CmsBookmarksV2 {
  readonly version: 2;
  readonly bookmarks: readonly CmsBookmarkV2[];
}

export type CmsBookmarks = CmsBookmarksV1 | CmsBookmarksV2;

export interface ResourceIdentifier {
  readonly id: string;
  readonly type: string;
}

export interface CmsEditorHistory {
  readonly version: 1;
  readonly recentlyOpened: readonly ResourceIdentifier[];
}

export interface InitialPublishingSite {
  readonly publishingSiteId: string;
}

export interface InitialProductLine {
  readonly productLineId: string;
}

export interface InitialCommerceLine {
  readonly commerceLineId: string;
}

export interface InitialContentSubset {
  readonly contentSubsetId: string;
}

export interface MultiverseStorage {
  readonly autoEnroll: string[];
  readonly allowList: string[];
  readonly releaseEnroll: string[];
}
