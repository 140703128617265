import { createContext, useContext, useMemo } from 'react';

// These imports are technically not allowed, but I don't want to move the types...
import type {
  Subdivision,
  SubdivisionStrategies,
} from '@studio/api/utils/subdivision';
import type { LoginMode } from '@studio/core-common/components/SessionManager';
import type { Experience } from '@studio/core-common/utils/experience';

import {
  type EnvironmentConfig,
  parseEnvironmentConfigFromHostname,
} from './fromEnvironment';
import useAbortSignal from './useAbortSignal';

/**
 * This is used to prevent creating a new RequestContext instance without extending a previous.
 * It should NOT be exported!
 */
const RequestContextSymbol = Symbol('RequestContext');

export interface RequestContext {
  readonly [RequestContextSymbol]: unknown;
  readonly signal: AbortSignal;
  readonly environment: EnvironmentConfig | null;
  readonly loginMode: LoginMode | null;
  /** Used by the Users API to get the ID of the logged-in User */
  readonly userId: string | null;
  //TODO: Spread experience into the RequestContext type
  // since deep level property change don't trigger re-renders
  readonly experience: Experience | null;
  /** @deprecated */
  readonly subdivision: Subdivision | null;
  readonly subdivisionStrategies: SubdivisionStrategies | null;
  readonly publishingSiteId: string | null;
  /** @deprecated */
  readonly commerceLineId: string | null;
}

export function isRequestContext(value: unknown): value is RequestContext {
  return (
    value !== null && typeof value === 'object' && RequestContextSymbol in value
  );
}

export function isEqualRequestContexts(
  a: RequestContext,
  b: RequestContext,
): boolean {
  return (
    a.environment === b.environment &&
    a.loginMode === b.loginMode &&
    a.userId === b.userId &&
    a.subdivision?.tenant === b.subdivision?.tenant &&
    a.subdivision?.market === b.subdivision?.market &&
    a.subdivisionStrategies === b.subdivisionStrategies &&
    a.publishingSiteId === b.publishingSiteId &&
    a.commerceLineId === b.commerceLineId &&
    a.experience?.studioExperience === b.experience?.studioExperience &&
    a.experience?.subdivisionMarket === b.experience?.subdivisionMarket &&
    a.experience?.subdivisionTenant === b.experience?.subdivisionTenant &&
    a.experience?.publishingSite === b.experience?.publishingSite &&
    a.experience?.productLine === b.experience?.productLine &&
    a.experience?.commerceLine === b.experience?.commerceLine
  );
}

/** Avoid using this directly - prefer extending an injected context */
export const DEFAULT_REQUEST_CONTEXT: RequestContext = {
  [RequestContextSymbol]: undefined,
  // The default signal never aborts
  // TODO: should we use a timeout signal here instead?
  get signal() {
    const controller = new AbortController();

    if (import.meta.webpackHot != null) {
      import.meta.webpackHot.dispose(() => {
        controller.abort();
      });
    }

    return controller.signal;
  },
  get environment() {
    return parseEnvironmentConfigFromHostname() ?? null;
  },
  loginMode: null,
  userId: null,
  subdivision: null,
  subdivisionStrategies: null,
  publishingSiteId: null,
  commerceLineId: null,
  experience: null,
};

export const RequestContextContext = createContext<RequestContext>(
  DEFAULT_REQUEST_CONTEXT,
);

export const { Provider: RequestContextProvider } = RequestContextContext;

/**
 * When you want to use access the raw request context and
 * You Know What You're Doing.
 *
 * Please make sure you supply your own abort signal
 */
export function useRequestContext_UNSAFE(): RequestContext {
  return useContext(RequestContextContext);
}

/**
 * Get the current request context, with an abortsignal bound to this component's lifecycle
 */
export function useRequestContext(): RequestContext {
  const context = useContext(RequestContextContext);
  const signal = useAbortSignal();
  return useMemo(() => ({ ...context, signal }), [context, signal]);
}
