import keys from './keys';

export default function mapValues<T extends object, V>(
  obj: T,
  mapValue: <K extends keyof T>(prevValue: T[K], prevKey: K) => V,
): Record<keyof T, V> {
  const newObj: Partial<Record<keyof T, V>> = {};
  for (const key of keys(obj)) {
    newObj[key] = mapValue(obj[key], key);
  }
  // @ts-expect-error - At this point it's complete
  return newObj;
}
