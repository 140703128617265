export default function parseRetryAfterHeader(
  header: string | null,
): number | null {
  if (header === null) {
    return null;
  }

  const retryInSeconds = Number.parseInt(header, 10);
  if (!Number.isNaN(retryInSeconds)) {
    return retryInSeconds * 1000;
  }

  const retryAtTimestamp = Date.parse(header);
  if (!Number.isNaN(retryAtTimestamp)) {
    return Date.now() - retryAtTimestamp;
  }

  return null;
}
