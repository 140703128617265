import invariant from '@studio/utils/invariant';
import mapValues from '@studio/utils/mapValues';
import type { Branded } from '@studio/utils/types';

import type { MaybeInlined } from './inlining';
import type {
  AnyResource,
  ResourceIdentifierObject,
  TransformedAttribute,
} from './types';

// Composite key of the resource type and id.
// Signature type:id
export type ResourceKey = Branded<string, 'ResourceKey'>;

export function createResourceKey({
  type,
  id,
}: Pick<AnyResource, 'id' | 'type'>): ResourceKey {
  return `${type}:${id}` as ResourceKey;
}

export function createResourceIdentifier<Resource extends AnyResource>({
  type,
  id,
}: MaybeInlined<Resource>): ResourceIdentifierObject<Resource> {
  invariant(typeof id === 'string', 'id must be a string');
  return { type, id };
}

export function transformAttributes<T extends object>(
  attributes: T,
): { [P in keyof T]: TransformedAttribute<T[P]> } {
  // @ts-expect-error - tricky generics
  return mapValues(attributes, value => value ?? undefined);
}

export function equalByResourceIdentifier(
  a: ResourceIdentifierObject | undefined,
  b: ResourceIdentifierObject | undefined,
): boolean {
  if (a === undefined || b === undefined) {
    return false;
  }

  return a.type === b.type && a.id === b.id;
}

export function isResourceIdentifierObjectStrict(
  resource: AnyResource,
): resource is ResourceIdentifierObject {
  const keyLength = Object.keys(resource).length;

  if (keyLength === 2) {
    return resource.id !== undefined && resource.type !== undefined;
  }

  if (keyLength === 3) {
    return (
      resource.id !== undefined &&
      resource.type !== undefined &&
      resource.meta !== undefined
    );
  }

  return false;
}

export function isResourceWithId(
  resource: MaybeInlined<AnyResource>,
): resource is MaybeInlined<AnyResource> & { id: string } {
  return resource?.id != null;
}

export class ResourceStore<Resource extends AnyResource = AnyResource> {
  readonly #store = new Map<ResourceKey, Resource>();

  get size(): number {
    return this.#store.size;
  }

  has(resource: Resource): boolean {
    return this.#store.has(createResourceKey(resource));
  }

  get<SomeResource extends Resource>(
    resourceIdentifier: ResourceIdentifierObject<SomeResource>,
  ): SomeResource | undefined {
    // @ts-expect-error - If the resource key exists, the subtype is correct
    return this.#store.get(createResourceKey(resourceIdentifier));
  }

  upsert(
    resources:
      | Resource
      | Iterable<Resource | null | undefined>
      | null
      | undefined,
  ): void {
    if (resources != null) {
      if (Symbol.iterator in resources) {
        for (const resource of resources) {
          this.upsert(resource);
        }
      } else {
        this.#store.set(createResourceKey(resources), resources);
      }
    }
  }

  clear(): void {
    this.#store.clear();
  }

  values(): IteratorObject<Resource, undefined> {
    return this.#store.values();
  }
}
