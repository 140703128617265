import { type ExtractRouteParams, generatePath } from 'react-router';

import id from '@studio/utils/id';

export type RouteParams<SomeRoute> =
  SomeRoute extends Route<infer Pattern, any>
    ? ExtractRouteParams<Pattern, string> extends infer T
      ? // flatten and make readonly
        { readonly [K in keyof T]: T[K] }
      : never
    : never;

type CreatePathParams<Pattern extends string> =
  ExtractRouteParams<Pattern, string | number> extends infer T
    ? // flatten and make readonly
      { readonly [K in keyof T]: T[K] }
    : never;

// If you don't override the createParams function,
// it will default to the inferred path params
type DefaultArgs<Pattern extends string> =
  keyof CreatePathParams<Pattern> extends never
    ? []
    : Pick<CreatePathParams<Pattern>, never> extends CreatePathParams<Pattern>
      ? [params?: CreatePathParams<Pattern> & object]
      : [params: CreatePathParams<Pattern>];

export interface Route<
  Pattern extends `/${string}`,
  Args extends unknown[] = DefaultArgs<Pattern>,
> {
  (...args: Args): string;
  readonly pattern: Pattern;
}

export interface RouteOptions<
  Pattern extends string,
  Args extends unknown[] = DefaultArgs<Pattern>,
> {
  createParams?(...args: Args): CreatePathParams<Pattern>;
  decodePath?(path: string): string;
}

export function createBaseRoute<
  Pattern extends `/${string}`,
  Args extends unknown[] = DefaultArgs<Pattern>,
>(
  pattern: Pattern,
  {
    // @ts-expect-error - this works
    createParams = id,
    decodePath = id,
  }: RouteOptions<Pattern, Args> = {},
): Route<Pattern, Args> {
  const createPath = (...args: Args) => {
    const params = createParams(...args);
    // @ts-expect-error - not sure why it's not working
    const path = generatePath(pattern, params);
    return decodePath(path);
  };
  createPath.pattern = pattern;
  return createPath;
}

type TrimEndSlash<S extends string> = S extends `${infer S2}/` ? S2 : S;
function trimEndSlash<S extends string>(str: S): TrimEndSlash<S> {
  // @ts-expect-error - TS doesn't understand the the string will be extracted
  return str.endsWith('/') ? str.slice(0, -1) : str;
}

type ResolvePattern<BasePattern extends `/${string}`, Pattern extends string> =
  // Extract<A, B> works like a type cast (A as B)
  Extract<`${TrimEndSlash<BasePattern>}/${Pattern}`, `/${string}`>;

function resolvePattern<
  BasePattern extends `/${string}`,
  Pattern extends string,
>(
  basePattern: BasePattern,
  pattern: Pattern,
): ResolvePattern<BasePattern, Pattern> {
  // @ts-expect-error - TS doesn't understand that the string ends with / if basePattern does
  return `${trimEndSlash(basePattern)}/${pattern}`;
}

export function createRoute<
  BasePattern extends `/${string}`,
  Pattern extends string,
  Args extends unknown[] = DefaultArgs<ResolvePattern<BasePattern, Pattern>>,
>(
  baseRoute: Route<BasePattern, any>,
  pattern: Pattern,
  options?: RouteOptions<ResolvePattern<BasePattern, Pattern>, Args>,
): Route<ResolvePattern<BasePattern, Pattern>, Args> {
  return createBaseRoute(resolvePattern(baseRoute.pattern, pattern), options);
}
