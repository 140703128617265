export default function ensureError<MaybeError>(
  maybeError: MaybeError,
): MaybeError extends Error ? MaybeError : Error {
  if (maybeError instanceof Error) {
    // @ts-expect-error - conditional return type
    return maybeError;
  }

  // @ts-expect-error - conditional return type
  return new Error(String(maybeError));
}
