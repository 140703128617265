import { useHistory } from 'react-router';

import invariant from '@studio/utils/invariant';

import { type HybridHistory, isHybridHistory } from './hybridHistory';

export default function useHybridHistory(): HybridHistory {
  const history = useHistory();
  invariant(isHybridHistory(history), 'history object is not a hybrid history');
  return history;
}
