import { useCallback } from 'react';

import useLatestRef from './useLatestRef';

type AnyFunction = (...args: readonly any[]) => any;

// https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
export default function useEventCallback<Fn extends AnyFunction>(
  fn: Fn,
): keyof Fn extends keyof AnyFunction
  ? Fn
  : Omit<Fn, Exclude<keyof Fn, keyof AnyFunction>> {
  const fnRef = useLatestRef(fn);
  // @ts-expect-error - workaround for generic return type
  return useCallback(
    (...args: Parameters<Fn>): ReturnType<Fn> => fnRef.current(...args),
    [fnRef],
  );
}
