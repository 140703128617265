import createStorageStore from '@studio/utils/createStorageStore';

export const lastRealmStore = createStorageStore(localStorage, 'lastRealm');

export const oktaAuthProviderStore = createStorageStore<boolean>(
  localStorage,
  'lastUserHasOktaAuthProvider',
  {
    encode(value) {
      return value ? 'true' : null;
    },
    decode(string) {
      return string === 'true';
    },
  },
);

/** @deprecated */
export const sonicAuthedStore = createStorageStore<boolean>(
  localStorage,
  'dplay.SONIC_AUTHED',
  {
    encode(value) {
      return value ? '1' : null;
    },
    decode(string) {
      return string === '1';
    },
  },
);
