import createReadonlySet from './createReadonlySet';
import values from './values';

export type EnumValidator<Enum extends Record<keyof Enum, unknown>> = (
  value: unknown,
) => value is Enum[Extract<keyof Enum, string>];

export default function createEnumValidator<
  Enum extends Record<keyof Enum, unknown>,
>(enumerator: Enum): EnumValidator<Enum> {
  const enumValues = createReadonlySet<unknown>(values(enumerator));
  return function isEnumValue(
    value: unknown,
  ): value is Enum[Extract<keyof Enum, string>] {
    return enumValues.has(value);
  };
}
