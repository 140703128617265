export default function memoizeWithWeakMap<This, T extends object, R>(
  fn: (this: This, obj: T) => R,
): {
  (this: This, obj: T): R;
  get cache(): WeakMap<T, R>;
} {
  const cache = new WeakMap<T, R>();
  function memoized(this: This, obj: T): R {
    if (!cache.has(obj)) {
      cache.set(obj, fn.call(this, obj));
    }
    return cache.get(obj)!;
  }
  memoized.cache = cache;
  return memoized;
}
