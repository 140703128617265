export type ValueOf<T> = T extends readonly unknown[] ? T[number] : T[keyof T];

export type ExtendProps<P1, P2> = P1 & Omit<P2, keyof P1>;

// Make all properties mandatory, but preserve undefined,
// so we don't forget to forward optional properties
export type IncludeOptionals<T> = { [K in keyof Required<T>]: T[K] };

export type FilterValues<T, V> = Pick<
  T,
  {
    [P in keyof T]: T[P] extends V ? P : never;
  }[keyof T]
>;

export type ExtractKeys<T, U> = {
  [K in keyof T]-?: Required<T>[K] extends U ? K : never;
}[keyof T];

declare const BRAND_SYMBOL: unique symbol;

export type Branded<Type, Brand> = Type & { [BRAND_SYMBOL]: Brand };

export type FixedLengthArray<
  T,
  Length extends number,
  Acc extends T[] = [],
> = number extends Length
  ? T[]
  : Acc['length'] extends Length
    ? Acc
    : FixedLengthArray<T, Length, [...Acc, T]>;

export type ReadonlyArrayOfMinLength<
  T,
  Length extends number,
  Acc extends readonly T[] = readonly [],
> = number extends Length
  ? readonly T[]
  : Acc['length'] extends Length
    ? readonly [...Acc, ...T[]]
    : ReadonlyArrayOfMinLength<T, Length, readonly [...Acc, T]>;

export type MinLength<
  Arr extends readonly unknown[],
  Acc extends readonly unknown[] = readonly [],
> = Arr extends readonly [infer Head, ...infer Tail]
  ? undefined extends Head
    ? Acc['length']
    : MinLength<Tail, readonly [...Acc, Head]>
  : Acc['length'];

export type Mutable<T> = {
  -readonly [K in keyof T]: T[K];
};

// prettier-ignore
export type DeepMutable<T> = 
  T extends ReadonlyMap<infer K, infer V> ? Map<K, DeepMutable<V>> :
  T extends ReadonlySet<infer V> ? Set<DeepMutable<V>> :
  T extends (...args: readonly any[]) => unknown ? T :
  T extends readonly unknown[] | object ? { -readonly [K in keyof T]: DeepMutable<T[K]> } :
  T;

/**
 * Cast an object as deeply mutable.
 * I HOPE YOU KNOW WHAT YOU'RE DOING!
 */
export function unsafe_asDeepMutable<T>(value: T): DeepMutable<T> {
  return value as DeepMutable<T>;
}

/**
 * Cast an object as mutable.
 * I HOPE YOU KNOW WHAT YOU'RE DOING!
 */
export function unsafe_asMutable<T>(value: T): Mutable<T> {
  return value as Mutable<T>;
}

export type ReadonlyRecord<K extends keyof any, V> = {
  readonly [_ in K]: V;
};

type AllUnionKeys<T> = T extends unknown ? keyof T : never;

export type LooseUnion<T> = T & {
  [K in Exclude<AllUnionKeys<T>, keyof T>]?: T extends { [_ in K]?: infer V }
    ? V
    : undefined;
};

export function asLooseUnion<T>(value: T): LooseUnion<T> {
  return value as LooseUnion<T>;
}

export type NonEmptyArray<T> = [T, ...T[]];

export type SymbolKey = {
  [key in symbol]?: string;
};

export type ReadonlyFlatten<T> = T extends unknown
  ? { readonly [K in keyof T]: T[K] }
  : never;

export type TaggedUnion<
  TagConfig extends Record<PropertyKey, PropertyKey>,
  States extends Record<ValueOf<TagConfig>, unknown>,
> = {
  [Tags in keyof States]: ReadonlyFlatten<
    Record<keyof TagConfig, Tags> & States[Tags]
  >;
}[keyof States];
