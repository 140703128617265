export default function tryParseInt(
  value: unknown,
  radix = 10,
): number | undefined {
  if (typeof value === 'number' && Number.isInteger(value)) {
    return value;
  }

  if (typeof value === 'string') {
    return tryParseInt(Number.parseInt(value, radix));
  }

  if (typeof value === 'bigint') {
    return Number(value);
  }

  return undefined;
}
