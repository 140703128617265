// @babel/preset-env will transform this into individual core-js modules based on the target
import 'core-js/stable';
// https://github.com/tc39/proposal-explicit-resource-management
import 'core-js/proposals/explicit-resource-management';
// https://github.com/tc39/proposal-arraybuffer-base64
import 'core-js/proposals/array-buffer-base64';
// https://github.com/tc39/proposal-array-from-async
import 'core-js/proposals/array-from-async-stage-2';

if (!Object.hasOwn(AbortSignal, 'timeout')) {
  /**
   * @param {AbortController} abortController
   * @param {number} timeoutMs
   */
  const abortControllerWithTimeout = abortController => {
    abortController.abort(new DOMException('Signal timed out', 'TimeoutError'));
  };

  Object.defineProperty(AbortSignal, 'timeout', {
    writable: true,
    enumerable: true,
    configurable: true,
    value: function timeout(timeoutMs) {
      const abortController = new AbortController();
      setTimeout(abortControllerWithTimeout, timeoutMs, abortController);
      return abortController.signal;
    },
  });
}

// https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/any_static
if (!Object.hasOwn(AbortSignal, 'any')) {
  const signalControllers = new WeakMap();

  const signalFinalizer = new FinalizationRegistry(
    ([signalRef, callbackRef]) => {
      try {
        const signal = signalRef.deref();
        if (signal !== undefined) {
          const callback = callbackRef.deref();
          if (callback !== undefined) {
            signal.removeEventListener('abort', callback);
          }
        }
      } catch {
        // do nothing
      }
    },
  );

  Object.defineProperty(AbortSignal, 'any', {
    writable: true,
    enumerable: true,
    configurable: true,
    /**
     * @param {Iterable<AbortSignal>} signals
     */
    value: function any(signals) {
      // return early if some signal is already aborted
      for (const signal of signals) {
        if (signal.aborted) {
          return signal;
        }
      }

      const abortController = new AbortController();

      // Force the signal to hold strongly onto its controller
      signalControllers.set(abortController.signal, abortController);

      const abortControllerRef = new WeakRef(abortController);

      /** @this {AbortSignal} */
      function handleAbort() {
        // Parent signal must not prevent child signals from being reclaimed
        abortControllerRef.deref()?.abort(this.reason);
      }

      for (const signal of signals) {
        signal.addEventListener('abort', handleAbort);
        signalFinalizer.register(
          abortController.signal,
          // Child signals must not prevent parent signals from being reclaimed
          [new WeakRef(signal), new WeakRef(handleAbort)],
        );
      }

      return abortController.signal;
    },
  });
}

// https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/reason
if (!Object.hasOwn(AbortSignal.prototype, 'reason')) {
  const abortReasons = new WeakMap();
  const abortControllerAbort = AbortController.prototype.abort;

  Object.defineProperty(AbortController.prototype, 'abort', {
    enumerable: true,
    configurable: true,
    value: function abort(
      reason = new DOMException('This operation was aborted', 'AbortError'),
    ) {
      abortReasons.set(this.signal, reason);
      return abortControllerAbort.call(this);
    },
  });

  Object.defineProperty(AbortSignal.prototype, 'reason', {
    enumerable: true,
    configurable: true,
    get() {
      return abortReasons.get(this);
    },
  });
}

if (!Object.hasOwn(AbortSignal.prototype, 'throwIfAborted')) {
  Object.defineProperty(AbortSignal.prototype, 'throwIfAborted', {
    writable: true,
    enumerable: false,
    configurable: true,
    value: function throwIfAborted() {
      if (this.aborted) {
        throw this.reason;
      }
    },
  });
}

if (!Object.hasOwn(Blob.prototype, 'arrayBuffer')) {
  Object.defineProperty(Blob.prototype, 'arrayBuffer', {
    writable: true,
    enumerable: true,
    configurable: true,
    value: function arrayBuffer() {
      return new Response(this).arrayBuffer();
    },
  });
}

if (!Object.hasOwn(Blob.prototype, 'stream')) {
  Object.defineProperty(Blob.prototype, 'stream', {
    writable: true,
    enumerable: true,
    configurable: true,
    value: function stream() {
      return new Response(this).stream();
    },
  });
}

if (!Object.hasOwn(Blob.prototype, 'text')) {
  Object.defineProperty(Blob.prototype, 'text', {
    writable: true,
    enumerable: true,
    configurable: true,
    value: function text() {
      return new Response(this).text();
    },
  });
}
