import withoutLeadingSlash from '@studio/utils/withoutLeadingSlash';

import { API_BASE_URL } from '../constants';

export function resolveApiURL(path: string, baseURL: URL = API_BASE_URL): URL {
  return new URL(withoutLeadingSlash(path), baseURL);
}

export function buildPath(
  constants: TemplateStringsArray,
  expressions: readonly (string | number)[],
  unsafe: boolean,
): string {
  let path = '';

  for (let i = 0; i < constants.length; i += 1) {
    path += constants[i]!;
    if (expressions[i] !== undefined) {
      path += unsafe
        ? encodeURI(expressions[i]!.toString())
        : encodeURIComponent(expressions[i]!);
    }
  }

  return path;
}

function createApiURL(unsafe: boolean) {
  return (
    constants: TemplateStringsArray,
    ...expressions: readonly (string | number)[]
  ): URL => {
    const path = buildPath(constants, expressions, unsafe);
    return resolveApiURL(path);
  };
}

export const apiURL = createApiURL(false);

/**
 * Use unsafeApiURL when your template argument has
 * characters you do not want to URL encode,
 * e.g. if you call apiUrl`/route/${customRoute}` where
 * customRoute := `someResource/some-id-string`
 * then call unsafeApiURL to avoid encoding the `/` as %2f
 */
export const unsafeApiURL = createApiURL(true);
