import LocalLockManager from './LocalLockManager';

export interface LockGuard extends Lock, AsyncDisposable {}

interface AcquireLockOptions {
  mode?: 'exclusive' | 'shared';
  signal?: AbortSignal;
}

interface TryAcquireLockOptions {
  mode?: 'exclusive' | 'shared';
  ifAvailable: boolean;
}

interface StealLockOptions {
  mode?: 'exclusive';
  steal: boolean;
}

export interface AcquireLock {
  (name: string, options?: AcquireLockOptions): Promise<LockGuard>;
  (name: string, options: TryAcquireLockOptions): Promise<LockGuard | null>;
  (name: string, options: StealLockOptions): Promise<LockGuard>;
}

export function createAcquireLock(lockManager: LockManager): AcquireLock {
  return function acquireLock(name, options = {}) {
    return new Promise<LockGuard>((acquire, rejectAcquire) => {
      const request = lockManager.request(name, options, lock => {
        if (lock === null) {
          // @ts-expect-error - This is only possible if options.ifAvailable is true
          acquire(null);
          return Promise.resolve();
        }

        return new Promise<void>(release => {
          acquire({
            name: lock.name,
            mode: lock.mode,
            [Symbol.asyncDispose]() {
              release();
              return request;
            },
          });
        });
      });

      request.catch(rejectAcquire);
    });
  };
}

const acquireLock = createAcquireLock(
  globalThis.navigator?.locks ?? new LocalLockManager(),
);

export default acquireLock;
