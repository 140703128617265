import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import hasOwn from '@studio/utils/hasOwn';

import translation from './res/en/translation.json';

const parseTranslation = (
  section: any,
): {
  [key: string]: any;
} =>
  hasOwn(section, 'template')
    ? section.template
    : Object.fromEntries(
        Object.entries(section).map(([key, value]) => [
          key,
          parseTranslation(value),
        ]),
      );

await i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: parseTranslation(translation),
      },
    },
  });
