import type { BoltEnv } from '@studio/utils/fromEnvironment';
import type { Branded } from '@studio/utils/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const STUDIO_IDENTITY_USER_ID_SYMBOL: unique symbol;
export type StudioIdentityUserId = Branded<
  `si:${BoltEnv}:${string}`,
  typeof STUDIO_IDENTITY_USER_ID_SYMBOL
>;

const STUDIO_IDENTITY_USER_ID_PATTERN = /^si:(?<env>.*?):(?<userId>.*?)$/;

export type UserId = StudioIdentityUserId | string;

export function extractUserId(
  studioIdentityUserId: StudioIdentityUserId,
): string {
  const match = STUDIO_IDENTITY_USER_ID_PATTERN.exec(studioIdentityUserId);
  const userId = match?.groups?.userId;

  if (userId == null) {
    throw new TypeError('Invalid SI User ID');
  }

  return userId;
}

export function isStudioIdentityUserId(
  value: unknown,
): value is StudioIdentityUserId {
  return (
    typeof value === 'string' && STUDIO_IDENTITY_USER_ID_PATTERN.test(value)
  );
}

export interface StudioIdentityTokenPayload {
  readonly iss?: string;
  readonly sub: StudioIdentityUserId | string;
  readonly iat?: number;
  readonly exp: number;
  readonly email: string;
  readonly mode: 'hybrid' | 'modern';
  readonly provider: string;
  readonly realm: string;
  readonly roles: readonly string[];
  readonly groups?: readonly string[];
}

export interface StudioIdentityTokenPayloadWithToken
  extends StudioIdentityTokenPayload {
  readonly token: StudioIdentityToken;
}

export type StudioIdentityToken = Branded<string, 'StudioIdentityToken'>;

export interface StudioIdentityTokenResponse {
  readonly token: StudioIdentityToken;
}

export interface StudioIdentityBasicUserInfo {
  readonly id: UserId;
  readonly email: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly managerTwId?: string;
}

export interface StudioIdentityUserInfo extends StudioIdentityBasicUserInfo {
  readonly roles: readonly string[];
  readonly publishingSites: readonly string[];
  readonly legacyId?: string;
  readonly legacyEmail?: string;
  readonly title?: string;
}

export interface StudioIdentityUserInfoResponse {
  readonly id: UserId;
  readonly email: string;
  readonly roles: readonly string[];
  readonly firstName?: string;
  readonly lastName?: string;
}

export interface StudioIdentityUsersRequest {
  readonly page?: number;
  readonly pageSize?: number;
  readonly email?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly login?: string;
  readonly sortColumn?: string;
  readonly sortOrder?: 'asc' | 'desc';
}

export interface StudioIdentityUsersMeta {
  readonly page: number;
  readonly pageSize: number;
  readonly totalItems: number;
}

export interface StudioIdentityUsersResponse {
  readonly users: readonly StudioIdentityUserInfo[];
  readonly meta: StudioIdentityUsersMeta;
}

export interface StudioIdentityUsersReportResponse {
  readonly data: readonly StudioIdentityUserInfo[];
  readonly meta: StudioIdentityUsersMeta;
}
