import { type ReactNode, useReducer } from 'react';

import { type SessionAction, SessionActionType } from './actions';
import { SessionDispatchContext, SessionStateContext } from './contexts';
import { useSessionEffects } from './effects';
import sessionReducer, {
  type SessionState,
  SessionStateStatus,
} from './reducer';
import { lastRealmStore } from './stores';
import { useSynchronizedDispatch } from './utils';

function getInitialSessionState(): SessionState {
  return {
    status: SessionStateStatus.RestoringSession,
    ignoreErrors: true,
    lastRealm: lastRealmStore.unsafe_getState(),
  };
}

function actionToSend(incomingAction: SessionAction): SessionAction | null {
  switch (incomingAction.type) {
    case SessionActionType.RestoreSessionSucceeded:
    case SessionActionType.UpdateUser:
    case SessionActionType.UpdatePreferences:
    case SessionActionType.Logout:
      return incomingAction;

    default:
      return null;
  }
}

export interface SessionManagerProps {
  children?: ReactNode;
}

export default function SessionManager({
  children,
}: SessionManagerProps): JSX.Element {
  const [state, rawDispatch] = useReducer(
    sessionReducer,
    undefined,
    getInitialSessionState,
  );

  const dispatch = useSynchronizedDispatch(
    rawDispatch,
    'SONIC_STUDIO',
    actionToSend,
  );

  useSessionEffects(state, dispatch);

  return (
    <SessionDispatchContext.Provider value={dispatch}>
      <SessionStateContext.Provider value={state}>
        {children}
      </SessionStateContext.Provider>
    </SessionDispatchContext.Provider>
  );
}
