import { createContext, type Dispatch, useContext } from 'react';

import type { SessionAction } from './actions';
import { type SessionState, SessionStateStatus } from './reducer';

export const SessionStateContext = createContext<SessionState>({
  status: SessionStateStatus.LoggedOut,
  lastError: null,
});

export const SessionDispatchContext = createContext<Dispatch<SessionAction>>(
  () => {
    throw new Error('No session dispatch context found!');
  },
);

export function useSessionState(): SessionState {
  return useContext(SessionStateContext);
}

export function useSessionDispatch(): Dispatch<SessionAction> {
  return useContext(SessionDispatchContext);
}
