export default function isEmptyObject(obj: unknown): boolean {
  if (obj == null) {
    return true;
  }

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      return false;
    }
  }

  return true;
}
