type BoltRegion = 'use1' | 'use2' | 'usw2';
export type BoltEnv = 'dev' | 'int' | 'stg' | 'prd';
type BoltStack = `${BoltRegion}-${BoltEnv}`;

type DiscoStack =
  | 'sonic-test'
  | 'eu1-test'
  | 'eu2-test'
  | 'us1-test'
  | 'ap2-test'
  | 'eu1-prod'
  | 'eu2-prod'
  | 'eu3-prod'
  | 'us1-prod'
  | 'ap2-prod'
  | 'eu-int';

export interface BoltEnvironmentConfig {
  readonly source: 'bolt';
  readonly type: 'test' | 'int' | 'stage' | 'prod' | undefined;
  readonly env: BoltEnv;
  readonly stack: BoltStack | undefined;
  readonly region: BoltRegion;
  readonly ingress: string;
}

export interface DiscoEnvironmentConfig {
  readonly source: 'disco';
  readonly type: 'test' | 'int' | 'prod' | undefined;
  readonly stack: DiscoStack | undefined;
  readonly region?: string;
  readonly ingress: string;
}

export type EnvironmentConfig = BoltEnvironmentConfig | DiscoEnvironmentConfig;
export type EnvironmentSource = EnvironmentConfig['source'];
export type EnvironmentType = NonNullable<EnvironmentConfig['type']>;
export type EnvironmentStack = NonNullable<EnvironmentConfig['stack']>;

const DISCO_V1_URL_PATTERN =
  /(?<region>\w+)-(?<env>test|int|prod+)(?:-(?<ingress>studio))?\.disco-api\.com/;

const DISCO_V2_URL_PATTERN =
  /(?:(?<ingress>studio)\.)?(?<region>\w+)\.(?<env>test|int|prod)\.disco-api\.com/;

const BOLT_URL_PATTERN =
  /(?:(?<ingress>studio)\.)?(?:(?<region>use1|use2|usw2)\.)?(?:(?<env>dev|int|stg|prd)\.)?(?:api\.)?discomax\.com/;

function parseDiscoEnvironmentType(discoEnv: string | undefined) {
  switch (discoEnv) {
    case 'test':
    case 'int':
    case 'prod':
      return discoEnv;
    default:
      return undefined;
  }
}

function parseDiscoV1Hostname(
  hostname: string,
): DiscoEnvironmentConfig | undefined {
  const match = DISCO_V1_URL_PATTERN.exec(hostname);
  if (match === null) {
    return undefined;
  }

  const { ingress = 'default', region, env } = match.groups ?? {};

  return {
    source: 'disco',
    type: parseDiscoEnvironmentType(env),
    stack:
      region === undefined || env === undefined
        ? undefined
        : (`${region}-${env}` as DiscoStack),
    region,
    ingress,
  };
}

function parseDiscoV2Hostname(
  hostname: string,
): DiscoEnvironmentConfig | undefined {
  const match = DISCO_V2_URL_PATTERN.exec(hostname);
  if (match === null) {
    return undefined;
  }

  const { ingress = 'default', region, env } = match.groups ?? {};

  return {
    source: 'disco',
    type: parseDiscoEnvironmentType(env),
    stack:
      region === undefined || env === undefined
        ? undefined
        : (`${region}-${env}` as DiscoStack),
    region,
    ingress,
  };
}

function parseBoltEnvironmentType(boltEnv: string) {
  switch (boltEnv) {
    case 'dev':
      return 'test';
    case 'int':
      return 'int';
    case 'stg':
      return 'stage';
    case 'prd':
      return 'prod';
    default:
      return undefined;
  }
}

function parseBoltHostname(
  hostname: string,
): BoltEnvironmentConfig | undefined {
  const match = BOLT_URL_PATTERN.exec(hostname);
  if (match === null) {
    return undefined;
  }

  const {
    ingress = 'default',
    region = 'use1',
    env = 'prd',
  } = match.groups ?? {};

  return {
    source: 'bolt',
    type: parseBoltEnvironmentType(env),
    env: env as BoltEnv,
    stack: `${region}-${env}` as BoltStack,
    region: region as BoltRegion,
    ingress,
  };
}

export function parseEnvironmentConfigFromHostname(
  hostname = process.env.API_URL ?? globalThis.location?.origin,
): EnvironmentConfig | undefined {
  return (
    parseBoltHostname(hostname) ??
    parseDiscoV1Hostname(hostname) ??
    parseDiscoV2Hostname(hostname)
  );
}

export function getStackName(hostname?: string): EnvironmentStack | undefined {
  return parseEnvironmentConfigFromHostname(hostname)?.stack;
}

/**
 * Do NOT use this for feature checking. Create a rule!
 * @deprecated
 */
export function getEnvironmentType(
  hostname?: string,
): EnvironmentType | undefined {
  return parseEnvironmentConfigFromHostname(hostname)?.type;
}

/**
 * This function is used to determine the region based on the stack name.
 * It will return the region as a string. E.g. us1, eu1, eu, ap2 or undefined.
 */
export function getEnvironmentRegion(hostname?: string): string | undefined {
  return parseEnvironmentConfigFromHostname(hostname)?.region;
}

export function getEnvironmentSource(
  hostname?: string,
): EnvironmentSource | undefined {
  return parseEnvironmentConfigFromHostname(hostname)?.source;
}

export function createBoltEnvironmentType(
  type: EnvironmentType | undefined,
): string | undefined {
  switch (type) {
    case 'test':
      return 'dev';
    case 'int':
      return 'int';
    case 'stage':
      return 'stg';
    case 'prod':
      return 'prd';
    default:
      return undefined;
  }
}

function createBoltHostname(
  env: BoltEnvironmentConfig | undefined,
): string | undefined {
  if (env === undefined) {
    return undefined;
  }

  const { ingress, region, type } = env;

  const ingressSegment = ingress === 'default' ? '' : `${ingress}.`;
  const regionSegment = region === 'use1' ? '' : `${region}.`;
  const envTypeSegment = `${createBoltEnvironmentType(type)}.`;

  return `${ingressSegment}${regionSegment}${envTypeSegment}api.discomax.com`;
}

function createDiscoHostname(
  env: DiscoEnvironmentConfig | undefined,
): string | undefined {
  if (env === undefined) {
    return undefined;
  }

  const { stack, ingress } = env;

  if (stack === 'eu-int') {
    return 'studio.eu.int.disco-api.com';
  }

  const ingressSegment = ingress === 'default' ? '' : `-${ingress}`;

  return `${stack}${ingressSegment}.disco-api.com`;
}

export function createHostnameFromEnvironmentConfig(
  env: EnvironmentConfig,
): string | undefined {
  switch (env.source) {
    case 'bolt':
      return createBoltHostname(env);
    case 'disco':
      return createDiscoHostname(env);
    default:
      return undefined;
  }
}

export function createContentOrchestratorUrl(
  env: EnvironmentConfig | undefined,
): string | undefined {
  const type = createBoltEnvironmentType(env?.type);

  if (type === undefined) {
    return undefined;
  }

  return `https://backoffice.${type}.api.discomax.com/prismatic-microapps/co-ui`;
}
