import { textDecoder, textEncoder } from './codecs';

/** @deprecated Prefer {@link Uint8Array.fromBase64} */
export function decodeBase64(base64String: string): string {
  const bytes = Uint8Array.fromBase64(base64String);
  return textDecoder.decode(bytes);
}

/** @deprecated Prefer {@link Uint8Array.prototype.toBase64} */
export function encodeBase64(string: string): string {
  const bytes = textEncoder.encode(string);
  return bytes.toBase64();
}

const BASE64URL_OPTIONS = { alphabet: 'base64url' } as const;

/** @deprecated Prefer {@link Uint8Array.fromBase64} */
export function decodeBase64Url(base64urlString: string): string {
  const bytes = Uint8Array.fromBase64(base64urlString, BASE64URL_OPTIONS);
  return textDecoder.decode(bytes);
}

/** @deprecated Prefer {@link Uint8Array.prototype.toBase64} */
export function encodeBase64Url(string: string): string {
  const bytes = textEncoder.encode(string);
  return bytes.toBase64(BASE64URL_OPTIONS);
}
