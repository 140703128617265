import { textDecoder } from '@studio/utils/codecs';

import type { StudioIdentityToken, StudioIdentityTokenPayload } from './types';

export function getTokenPayload(
  token: StudioIdentityToken,
): StudioIdentityTokenPayload {
  const [_header, payload, _signature] = token.split('.');

  const payloadBytes = Uint8Array.fromBase64(payload!, {
    alphabet: 'base64url',
  });
  const payloadString = textDecoder.decode(payloadBytes);
  const payloadJson = JSON.parse(payloadString);

  return payloadJson;
}

const PUBLISHING_SITE_ROLE_PREFIX = 'publishingsite:';

type PublishingSiteRole = `${typeof PUBLISHING_SITE_ROLE_PREFIX}${string}`;

export function isPublishingSiteRole(role: string): role is PublishingSiteRole {
  return role.startsWith(PUBLISHING_SITE_ROLE_PREFIX);
}

export function extractPublishingSite(
  publishingSiteRole: PublishingSiteRole,
): string {
  return publishingSiteRole.slice(PUBLISHING_SITE_ROLE_PREFIX.length);
}
