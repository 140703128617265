import { useMemo } from 'react';

import noop from '@studio/utils/noop';

import useSearchParams from './useSearchParams';

class RouteError extends Error {
  readonly code: string | undefined;

  constructor(
    errorType: string,
    errorDescription: string,
    errorCode: string | undefined,
  ) {
    super(errorDescription);
    this.name = errorType;
    this.code = errorCode;
  }
}

export default function useRouteError(): readonly [
  routeError: RouteError | undefined,
  clearRouteError: () => void,
] {
  const [
    {
      error: errorType,
      error_description: errorDescription,
      error_code: errorCode,
    },
    setSearchParams,
  ] = useSearchParams();

  return useMemo(() => {
    if (errorType == null) {
      return [undefined, noop];
    }

    const routeError = new RouteError(
      errorType,
      errorDescription ?? errorType,
      errorCode,
    );
    const clearRouteError = () => {
      setSearchParams(
        ({
          error: _error,
          error_description: _error_description,
          error_code: _error_code,
          ...rest
        }) => rest,
      );
    };
    return [routeError, clearRouteError];
  }, [errorCode, errorDescription, errorType, setSearchParams]);
}
