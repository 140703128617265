import type { RequestContext } from '@studio/utils/requestContext';

import { HttpError } from '../errors';
import identityApi from '../identity';
import usersApi from '../users';

interface CustomAttributesAPI {
  getCustomAttributes(
    context: RequestContext,
    customAttributesId: string,
  ): Promise<unknown>;

  setCustomAttributes(
    context: RequestContext,
    customAttributesId: string,
    customAttribute: unknown,
  ): Promise<void>;
}

export type LoginMode = 'MODERN' | 'HYBRID' | 'LEGACY';

function getApi(mode: LoginMode): CustomAttributesAPI {
  return mode === 'MODERN' ? identityApi : usersApi;
}

export interface CustomAttributes<
  out ID extends string,
  in out Attributes extends object,
  out DefaultAttributes extends Attributes | null,
> {
  readonly id: ID;
  readonly defaultAttributes: DefaultAttributes;

  getAttributes(
    context: RequestContext,
    mode: LoginMode,
  ): Promise<Attributes | DefaultAttributes>;

  setAttributes(
    context: RequestContext,
    mode: LoginMode,
    attributes: Attributes,
  ): Promise<Attributes>;
}

export function createCustomAttributes<
  const ID extends string,
  const Attributes extends object,
>(
  id: ID,
  isAttributes: (attributes: unknown) => attributes is Attributes,
): CustomAttributes<ID, Attributes, null>;

export function createCustomAttributes<
  const ID extends string,
  const Attributes extends object,
>(
  id: ID,
  isAttributes: (attributes: unknown) => attributes is Attributes,
  defaultAttributes: Attributes,
): CustomAttributes<ID, Attributes, Attributes>;

export function createCustomAttributes<
  const ID extends string,
  const Attributes extends object,
  const DefaultAttributes extends Attributes | null = null,
>(
  id: ID,
  isAttributes: (attributes: unknown) => attributes is Attributes,
  defaultAttributes: DefaultAttributes = null as DefaultAttributes,
): CustomAttributes<ID, Attributes, DefaultAttributes> {
  return {
    id,
    defaultAttributes,

    async getAttributes(context, mode) {
      const api = getApi(mode);

      try {
        const attributes = await api.getCustomAttributes(context, id);
        if (!isAttributes(attributes)) {
          console.warn(
            `Invalid attributes in ${id}, falling back to defaults!`,
            { before: attributes, after: defaultAttributes },
          );
          return defaultAttributes;
        }

        return attributes;
      } catch (error) {
        if (error instanceof HttpError && error.status === 404) {
          return defaultAttributes;
        }

        throw error;
      }
    },

    async setAttributes(context, mode, attributes) {
      if (!isAttributes(attributes)) {
        console.warn(`Invalid attributes in ${id}`, attributes);
        throw new TypeError(`Tried to insert invalid attributes in ${id}`);
      }
      const api = getApi(mode);

      await api.setCustomAttributes(context, id, attributes);

      return attributes;
    },
  };
}
