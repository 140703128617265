export const STUDIO_EXPERIENCES = [
  'max',
  'discoveryplus',
  'bleacherreport',
] as const;

export type StudioExperience = (typeof STUDIO_EXPERIENCES)[number];

export const SECONDARY_EXPERIENCES = [
  'subdivisionTenant',
  'subdivisionMarket',
  'publishingSite',
  'commerceLine',
  'productLine',
] as const;

export type SecondaryExperienceKey = (typeof SECONDARY_EXPERIENCES)[number];

export type SecondaryExperience = {
  readonly [_ in SecondaryExperienceKey]?: string | null;
};

export type SecondaryExperienceKeys = keyof SecondaryExperience;

export interface PersistedExperienceConfig {
  readonly studioExperience: StudioExperience | null;
  readonly secondaryExperiences: {
    readonly [key: string]: {
      readonly [_ in SecondaryExperienceKey]?: string | null;
    };
  };
}

export type ExperienceConfig = Readonly<{
  readonly [_ in StudioExperience]: {
    readonly [_ in SecondaryExperienceKey]?: readonly string[];
  };
}>;

export interface Experience extends SecondaryExperience {
  readonly studioExperience: StudioExperience | null;
}

export const HARDCODED_EXPERIENCE_CONFIG: ExperienceConfig = Object.freeze({
  max: {
    subdivisionTenant: ['beam'],
    subdivisionMarket: ['amer', 'latam', 'emea', 'apac'],
    publishingSite: ['max_global', 'fallback', 'beam_us'],
    commerceLine: ['3P1GD1'],
    productLine: ['c01c1c07-e7bf-4cee-be6b-87092a30d41c'],
  },
  discoveryplus: {
    subdivisionTenant: ['dplus'],
    subdivisionMarket: ['amer'],
    publishingSite: ['discovery_plus'],
    commerceLine: ['2RRCI7'],
    productLine: ['87671121-ed22-4ecb-8158-b5c2bd2472c3'],
  },
  bleacherreport: {
    subdivisionTenant: ['br'],
    subdivisionMarket: ['amer'],
    publishingSite: [],
    commerceLine: ['4Q2HE2'],
    productLine: ['d646434d-bcf9-4e40-88fb-c5ca52d8ccf0'],
  },
});
