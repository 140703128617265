import { useLayoutEffect, useRef } from 'react';

import noop from './noop';
import useEventCallback from './useEventCallback';
import useLatestRef from './useLatestRef';
import useThrowError from './useThrowError';

export interface BroadcastChannelOptions<T> {
  channelName: string;
  // TODO: enforce validatioin of incoming data
  onMessage?(data: T): void;
  skip?: boolean;
}

export interface BroadcastChannelActions<T> {
  postMessage(data: T): void;
}

export default function useBroadcastChannel<T>({
  channelName,
  onMessage = noop,
  skip = false,
}: BroadcastChannelOptions<T>): BroadcastChannelActions<T> {
  skip ||= process.env.NODE_ENV === 'test';

  const broadcastChannelRef = useRef<BroadcastChannel>();
  const throwError = useThrowError();
  const onMessageRef = useLatestRef(onMessage);

  useLayoutEffect(() => {
    // If BroadcastChannel isn't available, don't do anything
    // TODO: fallback to some other way?
    if (skip || typeof BroadcastChannel === 'undefined') {
      return noop;
    }

    const broadcastChannel = new BroadcastChannel(channelName);

    broadcastChannelRef.current = broadcastChannel;

    const handleMessage = (event: MessageEvent<T>) => {
      onMessageRef.current(event.data);
    };

    const handleMessageError = (event: MessageEvent<T>) => {
      throwError(Object.assign(new Error('Message error'), { event }));
    };

    broadcastChannel.addEventListener('message', handleMessage);
    broadcastChannel.addEventListener('messageerror', handleMessageError);

    return () => {
      broadcastChannel.close();
      broadcastChannel.removeEventListener('message', handleMessage);
      broadcastChannel.removeEventListener('messageerror', handleMessageError);
      broadcastChannelRef.current = undefined;
    };
  }, [channelName, onMessageRef, skip, throwError]);

  const postMessage = useEventCallback((message: T) => {
    broadcastChannelRef.current?.postMessage(message);
  });

  return { postMessage };
}
