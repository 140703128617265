import { useState } from 'react';

import { isAbortError } from './abortError';

export type ThrowError = (error: unknown) => void;

export default function useThrowError(): ThrowError {
  const [error, setError] = useState<unknown>();
  if (error != null && !isAbortError(error, null)) {
    throw error;
  }
  return setError;
}
