import {
  isArrayOf,
  isEither,
  isNull,
  isObject,
  isOptional,
  isShapeOf,
  isString,
  isUndefined,
  type TypePredicate,
} from 'giltig';

import type {
  AnyResource,
  DataDocument,
  ErrorDocument,
  ErrorObject,
  Nullable,
  ResourceIdentifierObject,
} from './types';

export function isNullable<T>(
  predicate: TypePredicate<T>,
): TypePredicate<Nullable<T>> {
  return isEither(predicate, isUndefined, isNull);
}

export const isErrorObject = isShapeOf<ErrorObject>({
  id: isNullable(isString),
  status: isNullable(isString),
  code: isNullable(isString),
  title: isNullable(isString),
  detail: isNullable(isString),
  source: isNullable(
    isShapeOf({
      pointer: isNullable(isString),
      parameter: isNullable(isString),
    }),
  ),
  meta: isNullable(
    isShapeOf({
      reasonCode: isNullable(isString),
      reasonDetail: isNullable(isString),
    }),
  ),
});

export const isAnyResource: TypePredicate<AnyResource> = isShapeOf({
  id: isOptional(isEither(isNull, isString)),
  type: isString,
  attributes: isOptional(isObject),
  meta: isOptional(isObject),
});

const isIncludedArray = isArrayOf(isAnyResource);

export function isDataDocumentOf<
  Resource extends AnyResource | readonly AnyResource[] | null,
>(predicate: TypePredicate<Resource>): TypePredicate<DataDocument<Resource>> {
  return isShapeOf({
    data: predicate,
    included: isOptional(isIncludedArray),
    meta: isOptional(isObject),
  });
}

export const isResourceIdentifierObject = isShapeOf<ResourceIdentifierObject>({
  id: isString,
  type: isString,
  meta: isOptional(isObject),
  'version:id': isOptional(isString),
});

export const isErrorDocument = isShapeOf<ErrorDocument>({
  errors: isArrayOf(isErrorObject),
  meta: isOptional(isObject),
  message: isOptional(isString),
});
