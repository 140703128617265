import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

import id from './id';
import memoizeWithWeakMap from './memoizeWithWeakMap';
import type { Store } from './useStoreState';

export interface StorageStore<in out State> extends Store<State> {
  getKey(): string;
  setState(state: State): void;
}

export interface Codec<in out State> {
  encode(state: State): string | null;
  decode(string: string | null): State;
}

const DEFAULT_CODEC: Codec<string | null> = {
  encode: id,
  decode: id,
};

type StorageStoreFactory = <State>(
  key: string,
  codec: Codec<State>,
) => StorageStore<State>;

function createStorageStoreFactory(storage: Storage): StorageStoreFactory {
  const localListeners = new Map<string, Set<() => void>>();

  function notifyLocalListenersForKey(key: string) {
    const listenersByKey = localListeners.get(key);
    if (listenersByKey !== undefined) {
      for (const listener of listenersByKey) {
        try {
          listener();
        } catch (error) {
          queueMicrotask(() => {
            throw error;
          });
        }
      }
    }
  }

  function notifyLocalListeners() {
    for (const key of localListeners.keys()) {
      notifyLocalListenersForKey(key);
    }
  }

  return function createStorageStore(key, codec) {
    function handleStorageEvent(storageEvent: StorageEvent) {
      if (storageEvent.storageArea === storage) {
        if (storageEvent.key === null) {
          batchedUpdates(notifyLocalListeners);
        } else if (storageEvent.key === key) {
          batchedUpdates(notifyLocalListenersForKey, key);
        }
      }
    }

    return {
      getKey() {
        return key;
      },

      unsafe_getState() {
        return codec.decode(storage.getItem(key));
      },

      setState(state) {
        const string = codec.encode(state);

        if (string === null) {
          storage.removeItem(key);
        } else {
          storage.setItem(key, string);
        }

        batchedUpdates(notifyLocalListenersForKey, key);
      },

      subscribe(listener) {
        if (localListeners.size === 0) {
          window.addEventListener('storage', handleStorageEvent);
        }

        let listenersByKey = localListeners.get(key);

        if (listenersByKey === undefined) {
          listenersByKey = new Set();
          localListeners.set(key, listenersByKey);
        }

        listenersByKey.add(listener);

        return () => {
          listenersByKey = localListeners.get(key);

          if (listenersByKey !== undefined) {
            listenersByKey.delete(listener);
            if (listenersByKey.size === 0) {
              localListeners.delete(key);
            }
          }

          if (localListeners.size === 0) {
            window.removeEventListener('storage', handleStorageEvent);
          }
        };
      },
    };
  };
}

const getStorageStoreFactory = memoizeWithWeakMap(createStorageStoreFactory);

export default function createStorageStore(
  storage: Storage,
  key: string,
): StorageStore<string | null>;

export default function createStorageStore<State>(
  storage: Storage,
  key: string,
  codec: Codec<State>,
): StorageStore<State>;

export default function createStorageStore(
  storage: Storage,
  key: string,
  codec: Codec<string | null> = DEFAULT_CODEC,
): StorageStore<string | null> {
  return getStorageStoreFactory(storage)(key, codec);
}
