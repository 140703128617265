import * as fjp from 'fast-json-patch';

export function createPatch<T extends NonNullable<unknown>>(
  prev: T,
  next: T,
): fjp.Operation[] {
  return fjp.compare(prev, next);
}

export function applyPatch<T extends NonNullable<unknown>>(
  prev: T,
  patch: readonly fjp.Operation[],
): T {
  if (patch.length === 0) {
    return prev;
  }

  const next = structuredClone(prev);
  fjp.applyPatch(next, patch);

  return next;
}

export function rebase<
  const PrevBase extends NonNullable<unknown>,
  const PrevFork extends PrevBase,
  const NextBase extends NonNullable<unknown>,
>(
  prevFork: PrevFork,
  prevBase: PrevBase,
  nextBase: NextBase,
): NextBase & Pick<PrevFork, Exclude<keyof PrevFork, keyof PrevBase>> {
  // @ts-expect-error - not sure if correct
  return applyPatch(nextBase, createPatch(prevBase, prevFork));
}
