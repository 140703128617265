import {
  createPath,
  type Location,
  type LocationDescriptor as To,
} from 'history';
import { useCallback } from 'react';

import type { HybridHistory } from '.';
import useHybridHistory from './useHybridHistory';

export type NavigateOptions = {
  mode?: 'push' | 'pop' | 'replace';
  state?: unknown;
};

export interface NavigateFunction {
  (to: To | ((location: Location) => To), options?: NavigateOptions): void;
  (delta: number): void;
}

export default function useNavigate(): NavigateFunction {
  const history = useHybridHistory();

  return useCallback<NavigateFunction>(
    (to, options?: NavigateOptions) => {
      performNavigation(to, history, options?.mode, options?.state);
    },
    [history],
  );
}

function performNavigation(
  to: number | To | ((location: Location) => To),
  history: HybridHistory,
  mode: NavigateOptions['mode'] = 'push',
  state: NavigateOptions['state'],
) {
  if (typeof to === 'function') {
    to = to(history.location);
  }

  if (typeof to === 'number') {
    history.go(to);
  } else {
    const path = typeof to === 'string' ? to : createPath(to);

    if (mode === 'push') {
      history.push(path, state);
    } else if (mode === 'replace') {
      history.replace(path, state);
    } else if (mode === 'pop') {
      history.pop(path);
    }
  }
}
