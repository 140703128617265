import type { ErrorEvent } from '@sentry/types';

import {
  BUILD_VERSION,
  SENTRY_DSN,
  SENTRY_ORG,
  SENTRY_PROJECT,
} from '@studio/api/constants';
import { getErrorType, HttpError, reportError } from '@studio/api/errors';
import { getEnvironmentType } from '@studio/utils/fromEnvironment';
import { IGNORED_ERRORS } from '@studio/utils/ignoredErrors';

import { getAppNameFromPath } from '../../utils/getAppNameFromPath';

export const ignoreErrors = [
  /Loading (CSS )?chunk/,
  /Failed to fetch/,
  /NetworkError when attempting to fetch resource/,
  /ResizeObserver loop limit exceeded/,
  /ResizeObserver loop completed with undelivered notifications/,
  /Unexpected token '<'/,
  /IP not allowed/,
  /Provider found no matching, active, IAP subscription/,
  /AppleAppStore is having problems/,
  /Username already exists as google registered user/,
  /(^(Non-Error promise rejection captured with value: )(Object Not Found Matching Id:\d+, MethodName:update, ParamCount:4)?)$/,
  /getModifierState is not a function/,
];

const setApp = (event: ErrorEvent) => {
  if (typeof event.tags == 'object' && event.tags?.app === undefined) {
    event.tags['app'] = getAppNameFromPath(window.location.pathname);
  }
};

export async function initSentry(): Promise<(() => Promise<void>) | undefined> {
  if (SENTRY_DSN === undefined) {
    return undefined;
  }

  const SentryReact = await import(
    /* webpackChunkName: "sentry" */ '@sentry/react'
  );

  SentryReact.init({
    dsn: SENTRY_DSN,
    ignoreErrors,
    environment: getEnvironmentType(),
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#deny-urls
    denyUrls: [/googletagmanager\.com/],
    release: BUILD_VERSION,
    integrations: defaultIntegrations => [
      ...defaultIntegrations,
      SentryReact.browserTracingIntegration(),
      SentryReact.replayIntegration(),
      SentryReact.extraErrorDataIntegration({
        captureErrorCause: true,
      }),
      SentryReact.captureConsoleIntegration({
        levels: ['error'],
      }),
      SentryReact.reportingObserverIntegration({
        types: ['crash', 'intervention'],
      }),
    ],
    // We reports all events, by default
    sampleRate: 1,
    // transaction capture rate for performance monitoring.
    tracesSampleRate: 0.05,
    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
    // This sets the sample rate to be 10%.
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1,
    // Handle error filtering here instead of many regex
    beforeSend(event, { originalException: error, event_id }) {
      if (error instanceof Error && IGNORED_ERRORS.has(error)) {
        return null;
      }

      if (
        error instanceof HttpError &&
        event.user !== undefined &&
        event.tags !== undefined
      ) {
        const { username, roles, id } = event.user;
        setApp(event);
        const tags = event.tags;
        const type = getErrorType(error);
        reportError(error, type, {
          scopeData: {
            sentryUrl: `https://${SENTRY_ORG}.sentry.io/discover/${SENTRY_PROJECT}:${event_id}/`,
            realm: tags?.realm,
            app: tags.app,
            stackName: tags?.stackName,
            username,
            roles,
            id,
          },
        });
      }
      setApp(event);
      return event;
    },
  });

  return async () => {
    await SentryReact.close();
  };
}
