import '@studio/polyfill';
import './index.css';

import { BUILD_VERSION } from '@studio/api/constants';
import { getErrorType, reportError } from '@studio/api/errors';
import { initSentry } from '@studio/core/components/ErrorBoundary/sentry';

console.info('Build version:', BUILD_VERSION);

function onUnhandledError(event: ErrorEvent) {
  if (event.error instanceof Error) {
    reportError(event.error, getErrorType(event.error));
  }
}

function onUnhandledRejection(event: PromiseRejectionEvent) {
  if (event.reason instanceof Error) {
    reportError(event.reason, getErrorType(event.reason));
  }
}

window.addEventListener('error', onUnhandledError);
window.addEventListener('unhandledrejection', onUnhandledRejection);

const closeSentry = await initSentry();

if (import.meta.webpackHot !== undefined) {
  import.meta.webpackHot.accept();
  import.meta.webpackHot.dispose(() => {
    window.removeEventListener('error', onUnhandledError);
    window.removeEventListener('unhandledrejection', onUnhandledRejection);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    closeSentry?.();
  });
}
