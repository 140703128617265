import type { LoginMode } from '@studio/core-common/components/SessionManager';

export interface RequestInterceptor {
  (
    request: Request,
    // TODO: preferably we would just provide the request context here,
    // but we can't currently provide it since it's mixed with the request options
    loginMode: LoginMode | null,
  ): Request | PromiseLike<Request>;
}

export interface ResponseInterceptor {
  (
    response: Response,
    request: Request,
    loginMode: LoginMode | null,
  ): Response | PromiseLike<Response>;
}

const requestInterceptorsMutable = new Set<RequestInterceptor>();

const responseInterceptorsMutable = new Set<ResponseInterceptor>();

export function addRequestInterceptor(
  requestInterceptor: RequestInterceptor,
): () => void {
  requestInterceptorsMutable.add(requestInterceptor);
  return () => {
    requestInterceptorsMutable.delete(requestInterceptor);
  };
}

export function addResponseInterceptor(
  responseInterceptor: ResponseInterceptor,
): () => void {
  responseInterceptorsMutable.add(responseInterceptor);
  return () => {
    responseInterceptorsMutable.delete(responseInterceptor);
  };
}

export const requestInterceptors: ReadonlySet<RequestInterceptor> =
  requestInterceptorsMutable;

export const responseInterceptors: ReadonlySet<ResponseInterceptor> =
  responseInterceptorsMutable;
