const ABORT_ERROR_NAME = 'AbortError';
const TIMEOUT_ERROR_NAME = 'TimeoutError';

export interface AbortError extends Error {
  readonly name: typeof ABORT_ERROR_NAME;
}

export interface TimeoutError extends Error {
  readonly name: typeof TIMEOUT_ERROR_NAME;
}

export function createAbortError(
  message = 'signal is aborted without reason',
): AbortError {
  return new DOMException(message, ABORT_ERROR_NAME) as AbortError;
}

export function createTimeoutError(message = 'signal timed out'): TimeoutError {
  return new DOMException(message, TIMEOUT_ERROR_NAME) as TimeoutError;
}

export function isAbortError(
  error: unknown,
  signal: AbortSignal | undefined | null,
): error is AbortError | TimeoutError {
  return (
    (signal != null && signal.aborted && signal.reason === error) ||
    (typeof error === 'object' &&
      error !== null &&
      'message' in error &&
      typeof error.message === 'string' &&
      'name' in error &&
      (error.name === ABORT_ERROR_NAME ||
        error.name === TIMEOUT_ERROR_NAME ||
        ('cause' in error && isAbortError(error.cause, signal))))
  );
}
