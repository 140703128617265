import './bootstrap';
import './i18n';

import { lazy, StrictMode, Suspense } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { SessionManager } from '@studio/core/components/SessionManager';

import appPromise from './appPromise';

const App = lazy(() => appPromise);

const rootElement = document.getElementById('root')!;

render(
  <StrictMode>
    <SessionManager>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </SessionManager>
  </StrictMode>,
  rootElement,
);

if (import.meta.webpackHot !== undefined) {
  import.meta.webpackHot.accept();
  import.meta.webpackHot.dispose(() => {
    unmountComponentAtNode(rootElement);
  });
}
