import createLabels from '@studio/utils/createLabels';
import {
  type BoltEnv,
  parseEnvironmentConfigFromHostname,
} from '@studio/utils/fromEnvironment';

import { API_BASE_URL } from '../constants';

export type Tenant = 'beam' | 'br' | 'dplus';
export type Market = 'amer' | 'latam' | 'emea' | 'apac';

export type TenantMarket = {
  [T in Tenant]: `${T}-${(typeof SUPPORTED_MARKETS_BY_TENANT)[T][number]}`;
}[Tenant];

const SUPPORTED_MARKETS_BY_TENANT = {
  beam: ['amer', 'latam', 'emea', 'apac'],
  br: ['amer'],
  dplus: ['amer'],
} as const satisfies Readonly<Record<Tenant, readonly [Market, ...Market[]]>>;

export interface Subdivision {
  readonly tenant: Tenant;
  readonly market: Market;
}
export function getSupportedMarkets(tenant: Tenant): readonly Market[] {
  return SUPPORTED_MARKETS_BY_TENANT[tenant];
}

export function getDefaultMarket<const T extends Tenant>(
  tenant: T,
): (typeof SUPPORTED_MARKETS_BY_TENANT)[T][0] {
  return SUPPORTED_MARKETS_BY_TENANT[tenant][0];
}

export const DEFAULT_TENANT = 'beam' satisfies Tenant;
export const DEFAULT_MARKET = getDefaultMarket(DEFAULT_TENANT) satisfies Market;

export const DEFAULT_SUBDIVISION = {
  tenant: DEFAULT_TENANT,
  market: DEFAULT_MARKET,
} as const satisfies Subdivision;

export function isDefaultSubdivision(
  subdivision: Subdivision,
): subdivision is typeof DEFAULT_SUBDIVISION {
  return (
    subdivision.market === DEFAULT_SUBDIVISION.market &&
    subdivision.tenant === DEFAULT_SUBDIVISION.tenant
  );
}

export const tenantLabels = createLabels<Tenant>({
  beam: 'BEAM',
  br: 'BR',
  dplus: 'DPLUS',
});

export const marketLabels = createLabels<Market>({
  amer: 'United States & Canada (AMER)',
  latam: 'Latin America (LATAM)',
  emea: 'Europe (EMEA)',
  apac: 'Asia-Pacific (APAC)',
});

export const tenantMarketLabels = createLabels<TenantMarket>({
  'beam-amer': 'United States & Canada (Max)',
  'beam-latam': 'Latin America (Max)',
  'beam-emea': 'Europe (Max)',
  'beam-apac': 'Asia-Pacific (Max)',
  'br-amer': 'United States & Canada (Bleacher Report)',
  'dplus-amer': 'United States & Canada (Discovery Plus)',
});

export type SubdivisionStrategy =
  | 'tenant'
  | 'market'
  | 'tenant-market'
  | 'global';

export interface SubdivisionStrategyOverride {
  readonly path: `/${string}`;
  readonly strategy: SubdivisionStrategy | null;
}

export type SubdivisionStrategies = readonly SubdivisionStrategyOverride[];

const ENVIRONMENT_CONFIG = parseEnvironmentConfigFromHostname();

function computeSubdivisionHost(
  env: BoltEnv,
  subdivisionStrategy: SubdivisionStrategy,
  { tenant, market }: Subdivision,
): string {
  switch (subdivisionStrategy) {
    case 'tenant-market':
      return `studio.${tenant}-${market}.${env}.api.discomax.com`;
    case 'tenant':
      return `studio.${tenant}-any.${env}.api.discomax.com`;
    case 'market':
      return `studio.any-${market}.${env}.api.discomax.com`;
    case 'global':
      return `studio.any-any.${env}.api.discomax.com`;
    default:
      subdivisionStrategy satisfies never;
      throw new Error(`Invalid subdivision strategy: ${subdivisionStrategy}`);
  }
}

export function getSubdivisionURL(
  url: URL,
  subdivision: Subdivision,
  subdivisionStrategies: SubdivisionStrategies,
): URL {
  if (
    ENVIRONMENT_CONFIG?.source === 'bolt' &&
    url.href.startsWith(API_BASE_URL.href)
  ) {
    const path = API_BASE_URL.pathname.endsWith('/')
      ? url.pathname.slice(API_BASE_URL.pathname.length - 1)
      : url.pathname.slice(API_BASE_URL.pathname.length);

    for (const override of subdivisionStrategies) {
      if (path.startsWith(override.path)) {
        if (override.strategy !== null) {
          const subdivisionHost = computeSubdivisionHost(
            ENVIRONMENT_CONFIG.env,
            override.strategy,
            subdivision,
          );

          // We should probably create a new URL here...
          // protocol is always https
          url.protocol = 'https:';
          url.port = '';
          url.host = subdivisionHost;
        }

        break;
      }
    }
  }

  return url;
}
