import { useEffect, useMemo, useRef } from 'react';

import useSyncExternalStore from './useSyncExternalStore';

function useAbortSignalAborted(signal: AbortSignal): boolean {
  const [subscribe, getSnapshot] = useMemo(
    (): Parameters<typeof useSyncExternalStore<boolean>> => [
      cb => {
        signal.addEventListener('abort', cb);
        return () => void signal.removeEventListener('abort', cb);
      },
      () => signal.aborted,
    ],
    [signal],
  );

  return useSyncExternalStore(subscribe, getSnapshot);
}

/** Returns a signal that aborts when the component unmounts */
export default function useAbortSignal(): AbortSignal {
  const abortControllerRef = useRef<AbortController>();
  abortControllerRef.current ??= new AbortController();

  // This could happen if the effect cleanup ran before it actually unmounted
  const aborted = useAbortSignalAborted(abortControllerRef.current.signal);
  if (aborted) {
    abortControllerRef.current = new AbortController();
  }

  const abortController = abortControllerRef.current;

  // Strict Mode and React Refresh might cause this hook to run multiple times
  useEffect(() => {
    return () => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    };
  }, [abortController]);

  return abortController.signal;
}
