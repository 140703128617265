export const DEFAULT_DEBOUNCE_TIMEOUT = 231.5;
export const DEFAULT_POLL_INTERVAL = 10_000;

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 30, 50, 100];
export const DEFAULT_ROWS_PER_PAGE = 30;

export const DEFAULT_LOCALE =
  process.env.NODE_ENV === 'test' ? 'en-US' : undefined;

export const SIDEBAR_WIDTH = 240;
