type HasOwn = <T extends object, Key extends PropertyKey>(
  obj: T,
  key: Key,
) => obj is T & Record<Key, unknown>;

const hasOwn: HasOwn =
  (Object.hasOwn as HasOwn) ??
  Object.prototype.hasOwnProperty.call.bind(Object.prototype.hasOwnProperty);

export default hasOwn;
