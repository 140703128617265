import { SonicFeature } from '../features';
import { Role } from '../roles';
import { and, not, or, ref as rawRef, type Rule } from '../RuleEngine';
import type { AccessContext } from '../utils';
import { ContentPromotionFeature } from './contentPromotionfeatures';

// We can't do this unfortunately, since it triggers a recursion error
// const ref = rawRef<keyof typeof rules>;
const ref = rawRef<
  // Feature rule names
  | ContentPromotionFeature
  // Role rule names
  | keyof typeof roleRules
  // Any other string starting with either "has" or "can"
  | `has${string}`
  | `can${string}`
  | `is${string}`
>;

const roleRules = {
  isContentPromoter: { roles: Role.ContentPromoter },
} as const satisfies Record<`is${Capitalize<string>}`, Rule<AccessContext>>;

const featureFlagRules = {
  [ContentPromotionFeature.ContentPromotion]: or(
    { realm: 'go' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
    { realm: 'bolt' },
  ),

  [ContentPromotionFeature.ContentPromotionHopper]: or(
    { realm: 'go', publishingSite: 'dplus_us' },
    { realm: 'go', publishingSite: 'dplus_ca' },
    { realm: 'go', publishingSite: 'dplus_br' },
    { realm: 'dplay', publishingSite: 'dplus_dk' },
    { realm: 'dplay', publishingSite: 'dplus_fi' },
    { realm: 'dplay', publishingSite: 'dplus_it' },
    { realm: 'dplay', publishingSite: 'dplus_de' },
    { realm: 'dplay', publishingSite: 'dplus_nl' },
    { realm: 'dplay', publishingSite: 'dplus_no' },
    { realm: 'dplay', publishingSite: 'dplus_se' },
    { realm: 'dplay', publishingSite: 'dplus_uk' },
    { realm: 'dplay', publishingSite: 'dplus_es' },
    { realm: 'dplay', publishingSite: 'dplus_at' },
    { realm: 'dplay', publishingSite: 'dplus_ie' },
    { realm: 'dplusapac', publishingSite: 'dplus_ph' },
    and(
      { realm: 'bolt', publishingSite: 'beam_us' },
      or(
        not(ref(SonicFeature.StudioExperience)),
        { studioExperience: 'max' },
        { studioExperience: 'discoveryplus' },
      ),
    ),
  ),

  [ContentPromotionFeature.ContentPromotionGlobal]: and(
    { realm: 'bolt' },
    not({ publishingSite: 'beam_us' }),
    or(
      not(ref(SonicFeature.StudioExperience)),
      { studioExperience: 'max' },
      { studioExperience: 'discoveryplus' },
    ),
  ),

  [ContentPromotionFeature.ContentPromotionCampaigns]: or(
    { realm: 'go', publishingSite: 'dplus_us', env: 'test' },
    { realm: 'bolt', env: 'test' },
  ),

  [ContentPromotionFeature.ContentPromotionDplusApi]: or(
    { realm: 'go' },
    { realm: 'dplay' },
    { realm: 'dplusapac' },
  ),

  [ContentPromotionFeature.ImmersiveHero]: { realm: 'bolt' },

  [ContentPromotionFeature.HopperSettings]: and(
    { realm: 'bolt' },
    not({ publishingSite: 'beam_us' }),
  ),

  [ContentPromotionFeature.TaxonomyLinkCandidates]: { realm: 'bolt' },

  [ContentPromotionFeature.DistributionChannelCandidates]: {
    realm: 'bolt',
  },
} as const satisfies Record<ContentPromotionFeature, Rule<AccessContext>>;

const permissionRules = {
  canAccessContentPromotion: and(
    ref(ContentPromotionFeature.ContentPromotion),
    or(
      ref('isEditor'),
      ref('isEditorialReader'),
      ref('isSonicDeveloper'),
      ref('isSuperAdmin'),
    ),
    ref('isContentPromoter'),
  ),

  canUseGlobalHopper: and(
    ref(ContentPromotionFeature.ContentPromotionGlobal),
    or(
      ref('isSuperAdmin'),
      ref('isSonicDeveloper'),
      ref('isEditor'),
      ref('isEditorialReader'),
      ref('isGlobalEditor'),
      ref('isEMEAEditor'),
      ref('isLATAMEditor'),
      ref('isUCANEditor'),
    ),
    ref('isContentPromoter'),
  ),

  canAccessContentPromotionDplusApi: ref(
    ContentPromotionFeature.ContentPromotionDplusApi,
  ),

  canAccessImmersiveHero: ref(ContentPromotionFeature.ImmersiveHero),

  canAccessTaxonomyLinkCandidates: ref(
    ContentPromotionFeature.TaxonomyLinkCandidates,
  ),

  canAccessDistributionChannelCandidates: ref(
    ContentPromotionFeature.DistributionChannelCandidates,
  ),

  canManageHopperSettings: or(ref('isSuperAdmin'), ref('isSonicDeveloper')),
} as const satisfies Record<`can${Capitalize<string>}`, Rule<AccessContext>>;

const contentPromotionRules = {
  ...roleRules,
  ...featureFlagRules,
  ...permissionRules,
};

export default contentPromotionRules;
