import { HeaderName } from '../constants';
import parseRetryAfterHeader from './parseRetryAfterHeader';

const MAX_ATTEMPTS = 5;
const DEFAULT_DELAY = 1000;

/**
 * {@link https://httpwg.org/specs/rfc9110.html#safe.methods}
 */
const SAFE_METHODS: readonly string[] = ['GET', 'HEAD', 'OPTIONS', 'TRACE'];

/**
 * {@link https://httpwg.org/specs/rfc9110.html#idempotent.methods}
 */
const IDEMPOTENT_METHODS: readonly string[] = [
  ...SAFE_METHODS,
  'PUT',
  'DELETE',
];

// These statuses should trigger retries, if the method is idempotent
const RETRYABLE_STATUSES: readonly number[] = [429, 502, 503, 504];

export type RetryStrategy = (
  request: Request,
  response: Response,
  attempt: number,
) => false | number;

export const defaultRetryStrategy: RetryStrategy = (
  request,
  response,
  attempt,
) => {
  if (attempt > MAX_ATTEMPTS) {
    return false;
  }

  if (!IDEMPOTENT_METHODS.includes(request.method)) {
    return false;
  }

  if (!RETRYABLE_STATUSES.includes(response.status)) {
    return false;
  }

  const retryAfterMs = parseRetryAfterHeader(
    response.headers.get(HeaderName.RetryAfter),
  );

  if (retryAfterMs != null) {
    return retryAfterMs;
  }

  // Maybe use exponential backoff here?
  return DEFAULT_DELAY;
};
