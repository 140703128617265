import type { Location } from 'history';
import { type Dispatch, useEffect, useMemo, useReducer, useRef } from 'react';

import { DEFAULT_DEBOUNCE_TIMEOUT } from '@studio/utils/constants';
import keys from '@studio/utils/keys';
import type { Branded } from '@studio/utils/types';

import useHybridHistory from './useHybridHistory';

type RequiredFormattedState<UrlKeys extends string> = Record<
  UrlKeys,
  string | undefined
>;

export type FormattedState<UrlKeys extends string> = Partial<
  Record<UrlKeys, string>
>;

export type SearchParamsParser<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
> = (formattedState: FormattedState<UrlKeys>) => State;

export type SearchParamsFormatter<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
> = (state: State) => RequiredFormattedState<UrlKeys>;

export type SearchAction<T> =
  | Partial<T>
  | ((prevState: T, defaultState: T) => Partial<T>);

export type SearchString = Branded<string, 'SearchString'>;

const leadingQuestionmark = /^\?/;

function getLocationSearchString(location: Location): SearchString {
  return location.search.replace(leadingQuestionmark, '') as SearchString;
}

function parseSearchString<UrlKeys extends string>(
  searchString: SearchString,
): FormattedState<UrlKeys> {
  return Object.fromEntries(
    new URLSearchParams(searchString),
  ) as FormattedState<UrlKeys>;
}

// Creates a "full" search string, representing the entire formatted state
function createFullSearchString<UrlKeys extends string>(
  formattedState: FormattedState<UrlKeys>,
) {
  const searchParams = new URLSearchParams();
  for (const key of Object.keys(formattedState) as UrlKeys[]) {
    const value = formattedState[key];
    if (typeof value === 'string') {
      searchParams.append(key, value);
    }
  }
  return searchParams.toString() as SearchString;
}

// Creates a "short" search string, omitting default values
function createShortSearchString<UrlKeys extends string>(
  formattedState: FormattedState<UrlKeys>,
  formattedDefaultState: FormattedState<UrlKeys>,
) {
  const searchParams = new URLSearchParams();
  for (const key of Object.keys(formattedState) as UrlKeys[]) {
    const value = formattedState[key];
    if (typeof value === 'string' && value !== formattedDefaultState[key]) {
      searchParams.append(key, value);
    }
  }
  return searchParams.toString() as SearchString;
}

export interface UseCustomSearchParamsOptions<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
> {
  debounceDelay?: number;
  parse: SearchParamsParser<State, UrlKeys>;
  format: SearchParamsFormatter<State, UrlKeys>;
}

export type CustomSearchParams<State extends object> = readonly [
  state: State,
  setState: Dispatch<SearchAction<State>>,
  extra: {
    readonly isDirty: boolean;
    readonly createSearch: (action: SearchAction<State>) => SearchString;
  },
];

export default function useCustomSearchParams<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
>({
  debounceDelay = DEFAULT_DEBOUNCE_TIMEOUT,
  parse,
  format,
}: UseCustomSearchParamsOptions<State, UrlKeys>): CustomSearchParams<State> {
  const history = useHybridHistory();

  const { defaultState, defaultStateKeys } = useMemo(() => {
    const _defaultState = parse({});
    return {
      defaultState: _defaultState,
      defaultStateKeys: keys(_defaultState),
    };
  }, [parse]);

  const formattedDefaultState = useMemo(
    () => format(defaultState),
    [defaultState, format],
  );

  const searchParamsReducer = (
    state: State,
    action: SearchAction<State>,
  ): State => {
    const nextState: State = {
      ...state,
      ...(typeof action === 'function' ? action(state, defaultState) : action),
    };

    for (const key of defaultStateKeys) {
      nextState[key] ??= defaultState[key];
    }

    return nextState;
  };

  const [state, setState] = useReducer(searchParamsReducer, undefined, () =>
    parse(parseSearchString(getLocationSearchString(history.location))),
  );

  const { formattedState, fullSearchString } = useMemo(() => {
    const _formattedState = format(state);
    return {
      formattedState: _formattedState,
      fullSearchString: createFullSearchString(_formattedState),
    };
  }, [format, state]);

  const shortSearchString = useMemo(
    () => createShortSearchString(formattedState, formattedDefaultState),
    [formattedDefaultState, formattedState],
  );

  // Keep track of the search strings update we trigger, so we can ignore the incoming location update
  const prevFullSearchStringUpdateRef = useRef(fullSearchString);
  const pendingSearchUpdateRef = useRef(false);

  // Debounce updates to the search string
  useEffect(() => {
    // Skip update if it's not needed
    if (shortSearchString === getLocationSearchString(history.location)) {
      prevFullSearchStringUpdateRef.current = fullSearchString;
      return;
    }

    // Schedule an update to the url in the near future
    // console.log(`scheduling search: "${shortSearchString}"`);

    pendingSearchUpdateRef.current = true;

    const timeout = setTimeout(() => {
      pendingSearchUpdateRef.current = false;
      prevFullSearchStringUpdateRef.current = fullSearchString;

      // console.log(`updating search: "${shortSearchString}"`);
      history.replace({
        ...history.location,
        search: shortSearchString,
      });
    }, debounceDelay);

    return () => {
      pendingSearchUpdateRef.current = false;
      clearTimeout(timeout);
    };
  }, [debounceDelay, fullSearchString, history, shortSearchString]);

  // Subscribe to location changes, updating the local state if needed
  // It will also be re-run if either the parser or formatter changes
  useEffect(() => {
    const checkIncomingLocation = (location: Location) => {
      // Prevent overwriting the local state if there is a pending search update
      if (pendingSearchUpdateRef.current) {
        // console.log('skipping search check, currently scheduling update');
        return;
      }

      const searchString = getLocationSearchString(location);
      // console.log(`checking search "${searchString}"`);
      const nextState = parse(parseSearchString(searchString));
      const nextFullSearchString = createFullSearchString(format(nextState));

      if (prevFullSearchStringUpdateRef.current !== nextFullSearchString) {
        // Mark the update as handled
        prevFullSearchStringUpdateRef.current = nextFullSearchString;
        // console.log('updating state', nextState);
        setState(nextState);
      } else {
        // console.log('skipping state update');
      }
    };

    checkIncomingLocation(history.location);

    return history.listen(checkIncomingLocation);
  }, [format, history, parse]);

  return [
    state,
    setState,
    {
      isDirty: shortSearchString !== '',
      createSearch(action: SearchAction<State>) {
        return createShortSearchString(
          format(searchParamsReducer(state, action)),
          formattedDefaultState,
        );
      },
    },
  ] as const;
}

export interface CreateSearchParamsHookOptions<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
> {
  parse: SearchParamsParser<State, UrlKeys>;
  format: SearchParamsFormatter<State, UrlKeys>;
}

export interface SearchParamsStateOptions {
  debounceDelay?: number;
}

export type SearchParamsHook<State extends object> = (
  options?: SearchParamsStateOptions,
) => CustomSearchParams<State>;

/**
 * Create some state that is synchronized with the current URL query string.
 * Simply pass in a pair of parse and format functions to generate a hook.
 * @deprecated Prefer using {@link useCustomSearchParams} directly instead
 */
export function createSearchParamsHook<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
>({
  parse,
  format,
}: CreateSearchParamsHookOptions<State, UrlKeys>): SearchParamsHook<State> {
  /** @deprecated */
  return function useSearchParamsState({ debounceDelay } = {}) {
    return useCustomSearchParams({ debounceDelay, parse, format });
  };
}
