import {
  isArrayOf,
  isEither,
  isNumber,
  isOptional,
  isShapeOf,
  isString,
} from 'giltig';

import createReadonlySet from '@studio/utils/createReadonlySet';
import { IGNORED_ERRORS } from '@studio/utils/ignoredErrors';

import { BASE_URL } from './constants';
import { type ErrorDocument, isErrorDocument, isErrorObject } from './json-api';

const isErrorWithCode = isShapeOf({
  error: isString,
});

const isErrorWithCodeAndMessage = isShapeOf({
  code: isEither(isNumber, isString),
  message: isString,
  requestId: isOptional(isString),
  correlationId: isOptional(isString),
});

const isCampaignsError = isShapeOf({
  _embedded: isShapeOf({
    errors: isArrayOf(isErrorObject),
  }),
  message: isString,
});

function nonEmpty(str: string | null | undefined): string | undefined {
  return str === '' || str === null ? undefined : str;
}

export enum ApiErrorCode {
  AccountLocked = 'unauthorized.account.locked',
  IpNotAllowed = 'access.denied.ip.not.allowed',
  MfaRequired = 'access.denied.mfa.authentication',
  InvalidUser = 'invalid.user',
  InvalidToken = 'invalid.token',
  InvalidPayload = 'invalid.payload',
  InvalidPassword = 'invalid.password',
  PasswordNeedsReset = 'password.needs.reset',
  KnownBreachedPassword = 'breached.password',
}

function getDefaultErrorMessage(request: Request, response: Response): string {
  return `Request failed: ${response.status} ${request.method} ${request.url}`;
}

export interface HttpErrorOptions {
  request: Request;
  response: Response;
  message?: string | undefined;
}

export class HttpError extends Error {
  override name = 'HttpError';

  readonly #request: Request;
  readonly #response: Response;

  get status(): number {
    return this.#response.status;
  }

  get method(): string {
    return this.#request.method;
  }

  get url(): string {
    return this.#request.url;
  }

  get responseHeaders(): Headers {
    return this.#response.headers;
  }

  get defaultMessage(): string {
    return getDefaultErrorMessage(this.#request, this.#response);
  }

  static async fromRequestResponse(
    request: Request,
    response: Response,
  ): Promise<HttpError | null> {
    if (response.ok) {
      return null;
    }

    if (response.bodyUsed) {
      return new HttpError({ request, response });
    }

    let errorJson: unknown;
    try {
      errorJson = await response.json();
    } catch {
      return new HttpError({ request, response });
    }

    return (
      ApiError.fromRequestResponseErrorJson(request, response, errorJson) ??
      new HttpError({ request, response })
    );
  }

  constructor({
    request,
    response,
    message = getDefaultErrorMessage(request, response),
  }: HttpErrorOptions) {
    if (response.ok) {
      throw new Error('Cannot create an HttpError from a successful response!');
    }
    super(message);
    this.#request = request;
    this.#response = response;
  }

  hasCustomMessage(): boolean {
    return this.message !== this.defaultMessage;
  }

  toJSON(): object {
    return {
      message: this.message,
      status: this.status,
      method: this.method,
      url: this.url,
    };
  }
}

export interface ApiErrorOptions extends HttpErrorOptions {
  requestId?: string | undefined;
  code?: string | undefined;
  meta?: Readonly<Record<string, unknown>>;
}

export class ApiError extends HttpError {
  override name = 'ApiError';

  readonly code: ApiErrorCode | string | undefined;
  readonly requestId: string | undefined;
  readonly meta: Readonly<Record<string, unknown>> | undefined;

  static fromRequestResponseErrorJson(
    request: Request,
    response: Response,
    errorJson: unknown,
  ): ApiError | null {
    if (isErrorDocument(errorJson)) {
      // We only care about the first error
      const firstError = errorJson.errors[0];
      return new ApiError({
        request,
        response,
        requestId: firstError?.id ?? undefined,
        meta: firstError?.meta ?? undefined,
        message:
          nonEmpty(firstError?.meta?.reasonDetail) ??
          nonEmpty(firstError?.detail) ??
          nonEmpty(errorJson.message),
        code:
          nonEmpty(firstError?.meta?.reasonCode?.toString()) ??
          nonEmpty(firstError?.code),
      });
    }

    if (isCampaignsError(errorJson)) {
      return this.fromRequestResponseErrorJson(request, response, {
        message: errorJson.message,
        errors: errorJson._embedded.errors,
      } satisfies ErrorDocument);
    }

    if (isErrorWithCode(errorJson)) {
      return new ApiError({
        request,
        response,
        code: errorJson.error,
      });
    }

    if (isErrorWithCodeAndMessage(errorJson)) {
      return new ApiError({
        request,
        response,
        message: errorJson.message,
        code: errorJson.code.toString(),
        requestId: errorJson.requestId ?? errorJson.correlationId,
      });
    }

    return null;
  }

  constructor({
    request,
    response,
    message,
    code,
    requestId,
    meta,
  }: ApiErrorOptions) {
    super({ request, response, message });
    this.code = code;
    this.requestId = requestId;
    this.meta = meta;
  }

  override toJSON(): object {
    return {
      ...super.toJSON(),
      code: this.code ?? null,
      requestId: this.requestId ?? null,
      meta: this.meta ?? null,
    };
  }
}

export interface ErrorReport {
  messageToUser?: string;
  messageFromUser?: string;
  location?: Array<{
    type: string;
    id: string;
  }>;
  details?: {
    type: string;
    data?: object;
    scope?: object;
  };
}

const IGNORED_ERROR_STRINGS = new Set<string>();

function sendErrorReport(errorReport: ErrorReport) {
  if (process.env.NODE_ENV === 'development') {
    console.info('Not sending error report', errorReport);
  }

  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const url = errorReport.location?.find(l => l.type === 'url')?.id;
  // Unique string based on error url and message to handle specific errors
  const uniqueErrorString = `${url}-${errorReport.messageToUser}`;

  // Open Search Error logging from Kubernetes
  const reportErrorUrl = new URL('report_error', BASE_URL);

  if (!IGNORED_ERROR_STRINGS.has(uniqueErrorString)) {
    IGNORED_ERROR_STRINGS.add(uniqueErrorString);
    setTimeout(() => {
      IGNORED_ERROR_STRINGS.delete(uniqueErrorString);
    }, 10_000);

    navigator.sendBeacon(
      reportErrorUrl,
      JSON.stringify({
        severity: 'error',
        '@timestamp': new Date().toISOString(),
        ...errorReport,
      }),
    );
  }
}

export enum ReportedErrorType {
  Application = 'application_error',
  SchemaValidation = 'schema_validation_error',
  API = 'api_error',
}

export function getErrorType(error: Error): ReportedErrorType {
  // SchemaValidation only used in Site Builder
  return error instanceof HttpError
    ? ReportedErrorType.API
    : ReportedErrorType.Application;
}

export interface ReportErrorOptions {
  messageToUser?: string;
  messageFromUser?: string;
  errorLocations?: Partial<Record<string, string>>;
  errorData?: object;
  scopeData?: object;
}

export function reportError(
  error: Error,
  errorType: ReportedErrorType,
  {
    messageToUser = error.message,
    messageFromUser,
    errorLocations,
    errorData = error instanceof ApiError
      ? { code: error.code, requestId: error.requestId }
      : undefined,
    scopeData,
  }: ReportErrorOptions = {},
): void {
  if (IGNORED_ERRORS.has(error)) {
    return;
  }

  const location: ErrorReport['location'] = [];

  if (error.stack !== undefined) {
    location.push({ type: 'errorStack', id: error.stack });
  }

  location.push({ type: 'url', id: window.location.href });

  if (errorLocations !== undefined) {
    for (const [type, id] of Object.entries(errorLocations)) {
      if (id !== undefined) {
        location.push({ type, id });
      }
    }
  }

  sendErrorReport({
    messageToUser,
    messageFromUser,
    location,
    details: {
      type: errorType,
      data: errorData,
      scope: scopeData,
    },
  });
}

const invalidSessionErrorCodes = createReadonlySet<string>([
  ApiErrorCode.InvalidToken,
  ApiErrorCode.InvalidUser,
]);

export function isInvalidSessionErrorCode(
  errorCode: string | undefined,
): boolean {
  return errorCode !== undefined && invalidSessionErrorCodes.has(errorCode);
}
