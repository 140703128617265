import { createLocation, type LocationDescriptor as To } from 'history';
import { useCallback } from 'react';

import useHybridHistory from './useHybridHistory';

export interface HrefOptions {
  pop?: boolean;
}

export type { To };

export type CreateHref = (to: To, options?: HrefOptions) => string;

export default function useCreateHref(): CreateHref {
  const history = useHybridHistory();
  return useCallback(
    function createHref(to, { pop = false } = {}) {
      if (pop) {
        return history.createPopHref(to);
      } else {
        return history.createHref(
          typeof to === 'string' ? createLocation(to) : to,
        );
      }
    },
    [history],
  );
}
