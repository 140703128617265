import isEmptyObject from '@studio/utils/isEmptyObject';
import isReadonlyArray from '@studio/utils/isReadonlyArray';
import memoizeWithWeakMap from '@studio/utils/memoizeWithWeakMap';
import { unsafe_asMutable } from '@studio/utils/types';

type RuleValue = number | string | null;

type ContextValue = RuleValue | ReadonlySet<RuleValue> | readonly RuleValue[];

type AnyContext<Key extends PropertyKey = string> = {
  readonly [_ in Extract<Key, string>]-?: ContextValue;
};

export type Evaluator<Context> = {
  (context: Context): boolean;
  // TODO: this should return boolean | undefined
  (partialContext: Partial<Context>): boolean;
};

export type PartialEvaluator<Context> = (
  partialContext: Partial<Context>,
) => Rule<Context>;

export type OrRule<Context> = {
  readonly $or: readonly RuleOrRef<Context>[];
};

export type AndRule<Context> = {
  readonly $and: readonly RuleOrRef<Context>[];
};

export type NotRule<Context> = {
  readonly $not: RuleOrRef<Context>;
};

export type RefRule = {
  readonly $ref: string;
};

type ValurOrArrayValue<T> = T extends readonly unknown[]
  ? T[number]
  : T extends ReadonlySet<infer V>
    ? V
    : T;

type DynamicRule<Context> = {
  [K in Exclude<keyof Context, `$${string}`>]?: ValurOrArrayValue<Context[K]>;
};

export type Rule<Context> =
  | OrRule<Context>
  | AndRule<Context>
  | NotRule<Context>
  | RefRule
  | DynamicRule<Context>;

type RuleOrRef<Context> = Rule<Context> | string;

function isNotRule<Context>(rule: Rule<Context>): rule is NotRule<Context> {
  return Object.hasOwn(rule, '$not');
}

function isAndRule<Context>(rule: Rule<Context>): rule is AndRule<Context> {
  return Object.hasOwn(rule, '$and');
}

function isOrRule<Context>(rule: Rule<Context>): rule is OrRule<Context> {
  return Object.hasOwn(rule, '$or');
}

function isRefRule(rule: Rule<unknown>): rule is RefRule {
  return Object.hasOwn(rule, '$ref');
}

export function and<Context>(
  ...rules: readonly Rule<Context>[]
): AndRule<Context> {
  return { $and: rules };
}

export function or<Context>(
  ...rules: readonly Rule<Context>[]
): OrRule<Context> {
  return { $or: rules };
}

export function not<Context>(rule: Rule<Context>): NotRule<Context> {
  return { $not: rule };
}

export function ref<RuleName extends string>(ruleName: RuleName): RefRule {
  return { $ref: ruleName };
}

const EMPTY_CONTEXT = {} as const;
const ALWAYS_TRUE: Rule<unknown> = Object.freeze({});
const ALWAYS_FALSE: Rule<unknown> = Object.freeze({ $not: ALWAYS_TRUE });

function isAlwaysTrueRule(rule: Rule<unknown>): boolean {
  return rule === ALWAYS_TRUE || isEmptyObject(rule);
}

function isAlwaysFalseRule(rule: Rule<unknown>): boolean {
  return (
    rule === ALWAYS_FALSE || (isNotRule(rule) && isAlwaysTrueRule(rule.$not))
  );
}

function evaluateAlwaysTrue(): Rule<unknown> {
  return ALWAYS_TRUE;
}

function evaluateAlwaysFalse(): Rule<unknown> {
  return ALWAYS_FALSE;
}

function isReadonlySet(value: unknown): value is ReadonlySet<unknown> {
  return value instanceof Set;
}

export default class RuleEngine<Context extends AnyContext<keyof Context>> {
  readonly #rules: Readonly<Record<string, Rule<Context>>>;

  constructor(rules: Readonly<Record<string, Rule<Context>>>) {
    this.#rules = rules;

    const mutableRules = unsafe_asMutable(this.#rules);
    for (const ruleName of this.getRuleNames()) {
      const rule = this.getRuleOrThrow(ruleName);
      const partiallyEvaluate = this.createPartialEvaluator(rule);
      mutableRules[ruleName] = partiallyEvaluate(EMPTY_CONTEXT);
    }

    this.createPartialEvaluator = memoizeWithWeakMap(
      this.createPartialEvaluator,
    );
  }

  getRuleNames(): string[] {
    return Object.keys(this.#rules);
  }

  getRule(ruleName: string): Rule<Context> | undefined {
    return this.#rules[ruleName];
  }

  getRuleOrThrow(ruleName: string): Rule<Context> {
    const rule = this.getRule(ruleName);

    if (rule === undefined) {
      throw new Error(`No such rule ${ruleName}`);
    }

    return rule;
  }

  createEvaluator(rule: Rule<Context>): Evaluator<Context> {
    const partialEvaluator = this.createPartialEvaluator(rule);

    return function evaluate(context) {
      const partiallyEvaluatedRule = partialEvaluator(context);

      if (isAlwaysTrueRule(partiallyEvaluatedRule)) {
        return true;
      }

      if (isAlwaysFalseRule(partiallyEvaluatedRule)) {
        return false;
      }

      return false;
    };
  }

  createPartialEvaluator(rule: RuleOrRef<Context>): PartialEvaluator<Context> {
    if (typeof rule === 'string') {
      return this.createPartialEvaluator({ $ref: rule });
    }

    if (isAlwaysTrueRule(rule)) {
      return evaluateAlwaysTrue;
    }

    if (isAlwaysFalseRule(rule)) {
      return evaluateAlwaysFalse;
    }

    const keys = Object.keys(rule);

    if (keys.length > 1) {
      return this.#createPartialAndEvaluator({
        // @ts-expect-error - Split the object into multiple objects with 1 key each
        $and: keys.map(key => ({ [key]: rule[key] })),
      });
    }

    if (isOrRule(rule)) {
      return this.#createPartialOrEvaluator(rule);
    }

    if (isAndRule(rule)) {
      return this.#createPartialAndEvaluator(rule);
    }

    if (isNotRule(rule)) {
      return this.#createPartialNotEvaluator(rule);
    }

    if (isRefRule(rule)) {
      return this.#createPartialRefEvaluator(rule);
    }

    const ruleKey = keys[0]!;

    const ruleValue: RuleValue = rule[ruleKey as keyof typeof rule]!;

    if (ruleKey.startsWith('$')) {
      throw new Error(`Unknown meta field: "${ruleKey}"`);
    }

    if (isReadonlyArray(ruleValue)) {
      return this.#createPartialAndEvaluator({
        // @ts-expect-error - Special case that treats value array as implicit $and
        $and: ruleValue.map(childRuleValue => ({ [ruleKey]: childRuleValue })),
      });
    }

    if (isReadonlySet(ruleValue)) {
      return this.#createPartialAndEvaluator({
        // @ts-expect-error - Special case that treats value set as implicit $and
        $and: Array.from(ruleValue, childRuleValue => ({
          [ruleKey]: childRuleValue,
        })),
      });
    }

    return this.#createPartialDynamicEvaluator(rule, ruleKey, ruleValue);
  }

  #createPartialAndEvaluator(
    rule: AndRule<Context>,
  ): PartialEvaluator<Context> {
    const partialEvaluators = rule.$and.map(this.createPartialEvaluator, this);

    return function partiallyEvaluateAndRule(partialContext) {
      let partiallyEvaluatedRules: RuleOrRef<Context>[] | undefined;

      for (const partialEvaluator of partialEvaluators) {
        const partiallyEvaluatedRule = partialEvaluator(partialContext);

        // Short-circuit if the rule is always false
        if (isAlwaysFalseRule(partiallyEvaluatedRule)) {
          return ALWAYS_FALSE;
        }

        // Skip the rule if it's always true
        if (isAlwaysTrueRule(partiallyEvaluatedRule)) {
          continue;
        }

        partiallyEvaluatedRules ??= [];

        // Flatten nested and rules
        if (isAndRule(partiallyEvaluatedRule)) {
          partiallyEvaluatedRules.push(...partiallyEvaluatedRule.$and);
        } else {
          partiallyEvaluatedRules.push(partiallyEvaluatedRule);
        }
      }

      // If there are no rules, it's always true
      if (
        partiallyEvaluatedRules === undefined ||
        partiallyEvaluatedRules.length === 0
      ) {
        return ALWAYS_TRUE;
      }

      // If there is only one rule, we do not need an and wrapper
      if (partiallyEvaluatedRules.length === 1) {
        return partiallyEvaluatedRules[0]!;
      }

      return { $and: partiallyEvaluatedRules };
    };
  }

  #createPartialOrEvaluator(rule: OrRule<Context>): PartialEvaluator<Context> {
    const partialEvaluators = rule.$or.map(this.createPartialEvaluator, this);

    return function partiallyEvaluateOrRule(partialContext) {
      let partiallyEvaluatedRules: RuleOrRef<Context>[] | undefined;

      for (const partialEvaluator of partialEvaluators) {
        const partiallyEvaluatedRule = partialEvaluator(partialContext);

        // Short-circuit if the rule is always true
        if (isAlwaysTrueRule(partiallyEvaluatedRule)) {
          return ALWAYS_TRUE;
        }

        // Skip the rule if it's always false
        if (isAlwaysFalseRule(partiallyEvaluatedRule)) {
          continue;
        }

        partiallyEvaluatedRules ??= [];

        // Flatten nested or rules
        if (isOrRule(partiallyEvaluatedRule)) {
          partiallyEvaluatedRules.push(...partiallyEvaluatedRule.$or);
        } else {
          partiallyEvaluatedRules.push(partiallyEvaluatedRule);
        }
      }

      // If there are no rules, it's always false
      if (
        partiallyEvaluatedRules === undefined ||
        partiallyEvaluatedRules.length === 0
      ) {
        return ALWAYS_FALSE;
      }

      // If there is only one rule, we do not need an or wrapper
      if (partiallyEvaluatedRules.length === 1) {
        return partiallyEvaluatedRules[0]!;
      }

      return { $or: partiallyEvaluatedRules };
    };
  }

  #createPartialNotEvaluator(
    rule: NotRule<Context>,
  ): PartialEvaluator<Context> {
    const partialEvaluator = this.createPartialEvaluator(rule.$not);

    return function partiallyEvaluateNotRule(partialContext) {
      const partiallyEvaluatedRule = partialEvaluator(partialContext);

      if (isAlwaysTrueRule(partiallyEvaluatedRule)) {
        return ALWAYS_FALSE;
      }

      if (isAlwaysFalseRule(partiallyEvaluatedRule)) {
        return ALWAYS_TRUE;
      }

      // Double negation
      if (isNotRule(partiallyEvaluatedRule)) {
        return partiallyEvaluatedRule.$not;
      }

      return { $not: partiallyEvaluatedRule };
    };
  }

  #createPartialRefEvaluator(rule: RefRule): PartialEvaluator<Context> {
    const refRule = this.getRuleOrThrow(rule.$ref);
    const partialEvaluator = this.createPartialEvaluator(refRule);

    return function partiallyEvaluateRefRule(partialContext) {
      const partiallyEvaluatedRule = partialEvaluator(partialContext);

      if (
        isAlwaysTrueRule(partiallyEvaluatedRule) ||
        isAlwaysFalseRule(partiallyEvaluatedRule)
      ) {
        return partiallyEvaluatedRule;
      }

      return rule;
    };
  }

  #createPartialDynamicEvaluator(
    rule: DynamicRule<Context>,
    ruleKey: string,
    ruleValue: RuleValue,
  ): PartialEvaluator<Context> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return function partiallyEvaluate(partialContext) {
      switch (self.#evaluateKeyValue(ruleKey, ruleValue, partialContext)) {
        case true:
          return ALWAYS_TRUE;
        case false:
          return ALWAYS_FALSE;
        default:
          return rule;
      }
    };
  }

  #evaluateKeyValue(
    ruleKey: string,
    ruleValue: RuleValue,
    context: Partial<Context>,
  ): boolean | null {
    if (!Object.hasOwn(context, ruleKey)) {
      // If the key does not exist, the rule is undecided
      return null;
    }

    // We checked above that rulekey is a key in context
    const contextRuleValue: ContextValue | undefined =
      context[ruleKey as keyof typeof context];

    if (contextRuleValue === undefined) {
      return null;
    }

    if (isReadonlyArray(contextRuleValue)) {
      return contextRuleValue.includes(ruleValue);
    }

    if (isReadonlySet(contextRuleValue)) {
      return contextRuleValue.has(ruleValue);
    }

    return Object.is(contextRuleValue, ruleValue);
  }
}
