export enum ContentPromotionFeature {
  ContentPromotion = 'isContentPromotionEnabled',
  ContentPromotionHopper = 'isContentPromotionHopperEnabled',
  ContentPromotionGlobal = 'isContentPromotionGlobalEnabled',
  ContentPromotionCampaigns = 'isContentPromotionCampaignsEnabled',
  ContentPromotionDplusApi = 'isContentPromotionDplusApi',
  ImmersiveHero = 'isImmersiveHeroEnabled',
  HopperSettings = 'isHopperSettingsEnabled',
  TaxonomyLinkCandidates = 'isAllowTaxonomyLinkCandidates',
  DistributionChannelCandidates = 'isAllowDistributionChannelCandidates',
  // 🚨 DON'T REMOVE COMMENT : Register your new feature above
}
