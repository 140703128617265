interface EventTarget {
  addEventListener(
    this: this,
    type: string,
    listener: (this: this, ev: Event) => void,
    options?: AddEventListenerOptions,
  ): void;

  removeEventListener(
    this: this,
    type: string,
    listener: (this: this, ev: Event) => void,
  ): void;
}

export default function subscribe<const Target extends EventTarget>(
  target: Target,
  type: string,
  listener: (this: Target, ev: Event) => void,
  options?: AddEventListenerOptions,
): () => void {
  target.addEventListener(type, listener, options);
  return () => {
    target.removeEventListener(type, listener);
  };
}
