import isReadonlyArray from '@studio/utils/isReadonlyArray';
import keys from '@studio/utils/keys';
import type { Mutable } from '@studio/utils/types';

import {
  createResourceIdentifier as defaultCreateResourceIdentifier,
  createResourceKey as defaultCreateResourceKey,
  type ResourceKey,
} from './helpers';
import type {
  AnyResource,
  DataDocument,
  RelationshipObject,
  RelationshipsObject,
  ResourceIdentifierObject,
  ToManyRelationship,
  ToOneRelationship,
  TransformedAttribute,
} from './types';

type InlinedRelationship<Relationship extends RelationshipObject<AnyResource>> =
  Relationship extends ToOneRelationship<infer LinkedResource>
    ? MaybeInlined<LinkedResource> | undefined
    : Relationship extends ToManyRelationship<infer LinkedResource>
      ? readonly MaybeInlined<LinkedResource>[]
      : undefined;

export type InlinedRelationships<
  out Relationships extends RelationshipsObject,
> = {
  readonly [Name in keyof Relationships]?: InlinedRelationship<
    NonNullable<Relationships[Name]>
  >;
};

// Replace null values with undefined
type TransformedAttributes<out Attributes extends NonNullable<unknown>> = {
  readonly [AttributeName in keyof Attributes]: TransformedAttribute<
    Attributes[AttributeName]
  >;
};

export type Inlined<out Resource extends AnyResource> = {
  readonly [FieldName in keyof Resource]: FieldName extends 'attributes'
    ? TransformedAttributes<NonNullable<Resource[FieldName]>>
    : FieldName extends 'relationships'
      ? InlinedRelationships<NonNullable<Resource[FieldName]>>
      : Resource[FieldName];
};

export type MaybeInlined<Resource extends AnyResource> = Pick<
  Resource,
  'type' | 'id'
> &
  Partial<Inlined<Resource>>;

function transformAttributes<Attributes extends NonNullable<unknown>>(
  attributes: Attributes | undefined,
): TransformedAttributes<Attributes> | undefined {
  if (attributes === undefined) {
    return undefined;
  }

  const transformedAttributes: Partial<
    Mutable<TransformedAttributes<Attributes>>
  > = {};

  // Transform attributes (convert null to undefined)
  for (const attributeName of keys(attributes)) {
    // @ts-expect-error - generics...
    transformedAttributes[attributeName] =
      attributes[attributeName] ?? undefined;
  }

  // @ts-expect-error - generics...
  return transformedAttributes;
}

type ResourceKeyCreator = (resourceObject: any) => ResourceKey;
type ResourceIdentifierCreator = (
  maybeInlinedResource: any,
) => ResourceIdentifierObject;

export interface InlinerOptions {
  includedResources?: readonly AnyResource[];
  createResourceKey?: ResourceKeyCreator;
  createFallbackResourceKey?: ResourceKeyCreator;
  disableCircularReferences?: boolean;
}

export class Inliner {
  readonly #createResourceKey: ResourceKeyCreator;
  readonly #createFallbackResourceKey: ResourceKeyCreator;
  readonly #includedResources: ReadonlyMap<ResourceKey, AnyResource>;
  readonly #cachedInlinedResources = new Map<
    ResourceKey,
    Inlined<AnyResource>
  >();
  readonly #disableCircularReferences: boolean;

  constructor({
    includedResources,
    createResourceKey = defaultCreateResourceKey,
    createFallbackResourceKey = createResourceKey,
    disableCircularReferences = false,
  }: InlinerOptions = {}) {
    this.#createResourceKey = createResourceKey;
    this.#createFallbackResourceKey = createFallbackResourceKey;
    this.#includedResources = new Map(
      includedResources?.flatMap(resource => [
        [createResourceKey(resource), resource],
        [createFallbackResourceKey(resource), resource],
      ]),
    );
    this.#disableCircularReferences = disableCircularReferences;
  }

  inline<Resource extends AnyResource>(resource: Resource): Inlined<Resource> {
    const resourceKey = this.#createResourceKey(resource);

    // @ts-expect-error - not yet complete
    const inlinedResource: Mutable<Inlined<Resource>> = { ...resource };

    this.#cachedInlinedResources.set(resourceKey, inlinedResource);

    inlinedResource.attributes = transformAttributes(resource.attributes);
    inlinedResource.relationships = this.#inlineRelationships(
      resource.relationships,
    );

    return inlinedResource;
  }

  #inlineRelationships(
    relationships: RelationshipsObject | undefined,
  ): InlinedRelationships<RelationshipsObject> | undefined {
    if (relationships === undefined) {
      return undefined;
    }

    const inlinedRelationships: Partial<
      Mutable<InlinedRelationships<RelationshipsObject>>
    > = {};

    for (const relationshipName of keys(relationships)) {
      inlinedRelationships[relationshipName] = this.#inlineRelationship(
        relationships[relationshipName],
      );
    }

    return inlinedRelationships;
  }

  #inlineRelationship(
    relationship: RelationshipObject<AnyResource> | undefined,
  ): InlinedRelationship<RelationshipObject<AnyResource>> | undefined {
    if (relationship?.data == null) {
      return undefined;
    }

    if (isReadonlyArray(relationship.data)) {
      if (relationship.data.length === 0) {
        return relationship.data;
      }
      return relationship.data.map(this.#includedResource, this);
    }

    return this.#includedResource(relationship.data);
  }

  #includedResource(
    resourceIdentifier: ResourceIdentifierObject<AnyResource>,
  ): MaybeInlined<AnyResource> {
    const resourceKey = this.#createResourceKey(resourceIdentifier);

    const cachedInlinedResource = this.#cachedInlinedResources.get(resourceKey);

    if (cachedInlinedResource !== undefined) {
      return this.#disableCircularReferences
        ? resourceIdentifier
        : cachedInlinedResource;
    }

    const includedResource =
      this.#includedResources.get(resourceKey) ??
      this.#includedResources.get(
        this.#createFallbackResourceKey(resourceIdentifier),
      );

    if (includedResource !== undefined) {
      return this.inline(includedResource);
    }

    return resourceIdentifier;
  }
}

export function inlineDocument<const Resource extends AnyResource | null>(
  document: DataDocument<Resource>,
  options?: Omit<InlinerOptions, 'includedResources'>,
): Resource extends AnyResource ? Inlined<Resource> : null;

export function inlineDocument<const Resources extends readonly AnyResource[]>(
  document: DataDocument<Readonly<Resources>>,
  options?: Omit<InlinerOptions, 'includedResources'>,
): { readonly [N in keyof Resources]: Inlined<Resources[N]> };

export function inlineDocument<const Resource extends AnyResource>(
  document: DataDocument<readonly Resource[]>,
  options?: Omit<InlinerOptions, 'includedResources'>,
): readonly Inlined<Resource>[];

export function inlineDocument<
  const Document extends DataDocument<
    AnyResource | readonly AnyResource[] | null
  >,
>(
  document: Document,
  options?: Omit<InlinerOptions, 'includedResources'>,
): Document extends DataDocument<infer Resource>
  ? Resource extends readonly AnyResource[]
    ? {
        readonly [N in keyof Resource]: Inlined<
          Extract<Resource[N], AnyResource>
        >;
      }
    : Resource extends AnyResource
      ? Inlined<Resource>
      : Resource extends null
        ? null
        : never
  : never;

export function inlineDocument<const Resource extends AnyResource>(
  document: DataDocument<Resource | readonly Resource[] | null>,
  options?: Omit<InlinerOptions, 'includedResources'>,
): Inlined<Resource> | readonly Inlined<Resource>[] | null {
  if (document.data === null) {
    return null;
  }

  const inliner = new Inliner({
    ...options,
    includedResources: document.included,
  });

  if (isReadonlyArray(document.data)) {
    return document.data.map(inliner.inline, inliner);
  }

  return inliner.inline(document.data);
}

function untransformAttributes<Attributes extends object>(
  transformedAttributes: TransformedAttributes<Attributes> | undefined,
): Attributes | undefined {
  if (transformedAttributes === undefined) {
    return undefined;
  }

  // @ts-expect-error - not complete here, will transfrom it below
  const attributes: Attributes = {};

  for (const attributeName of keys(transformedAttributes)) {
    // @ts-expect-error - generics...
    attributes[attributeName] = transformedAttributes[attributeName] ?? null;
  }

  return attributes;
}

export interface UninlinerOptions {
  createResourceKey?: ResourceKeyCreator;
  createResourceIdentifier?: ResourceIdentifierCreator;
}

class Uninliner {
  readonly #createResourceKey: ResourceKeyCreator;
  readonly #createResourceIdentifier: ResourceIdentifierCreator;
  readonly #includedResources = new Map<ResourceKey, AnyResource>();
  readonly #includedBlacklist = new Set<ResourceKey>();

  constructor({
    createResourceKey = defaultCreateResourceKey,
    createResourceIdentifier = defaultCreateResourceIdentifier,
  }: UninlinerOptions = {}) {
    this.#createResourceKey = createResourceKey;
    this.#createResourceIdentifier = createResourceIdentifier;
  }

  getIncluded() {
    return Array.from(this.#includedResources.values());
  }

  uninline<Resource extends AnyResource>(
    inlinedResource: Inlined<Resource>,
  ): Resource {
    this.#includedBlacklist.add(this.#createResourceKey(inlinedResource));

    const attributes = untransformAttributes(inlinedResource.attributes);

    const relationships = this.#uninlineRelationships(
      inlinedResource.relationships,
    );

    // @ts-expect-error - generics...
    return { ...inlinedResource, attributes, relationships };
  }

  #uninlineRelationships(
    inlinedRelationships: InlinedRelationships<RelationshipsObject> | undefined,
  ): RelationshipsObject | undefined {
    if (inlinedRelationships === undefined) {
      return undefined;
    }

    const relationships: Partial<Mutable<RelationshipsObject>> = {};

    for (const relationshipName of keys(inlinedRelationships)) {
      relationships[relationshipName] = this.#uninlineRelationship(
        inlinedRelationships[relationshipName],
      );
    }

    return relationships;
  }

  #uninlineRelationship(
    inlinedRelationship:
      | InlinedRelationship<RelationshipObject<AnyResource>>
      | undefined,
  ): RelationshipObject<AnyResource> | undefined {
    if (inlinedRelationship === undefined) {
      return undefined;
    }

    if (isReadonlyArray(inlinedRelationship)) {
      return {
        data: inlinedRelationship.map(this.#maybeUninlineResource, this),
      };
    }

    return {
      data: this.#maybeUninlineResource(inlinedRelationship),
    };
  }

  #maybeUninlineResource(
    maybeInlinedResource: MaybeInlined<AnyResource>,
  ): ResourceIdentifierObject {
    if (maybeInlinedResource.id == null) {
      throw new TypeError(`Attempted to uninline a resource with no id`);
    }

    // It's not just a ResourceIdentifierObject, so keep unlining
    if (
      maybeInlinedResource.attributes !== undefined ||
      maybeInlinedResource.relationships !== undefined
    ) {
      const resourceKey = this.#createResourceKey(maybeInlinedResource);

      if (
        !this.#includedResources.has(resourceKey) &&
        !this.#includedBlacklist.has(resourceKey)
      ) {
        const resource = this.#createResourceIdentifier(maybeInlinedResource);
        this.#includedResources.set(resourceKey, resource);
        Object.assign(resource, this.uninline(maybeInlinedResource));
      }
    }

    return this.#createResourceIdentifier(maybeInlinedResource);
  }
}

export function uninlineResource<Resource extends AnyResource>(
  inlinedResource: Inlined<Resource>,
  options?: UninlinerOptions,
): DataDocument<Resource> {
  const uninliner = new Uninliner(options);

  const data = uninliner.uninline(inlinedResource);
  const included = uninliner.getIncluded();

  return { data, included };
}

export function uninlineResources<
  const InlinedResources extends readonly Inlined<AnyResource>[],
>(
  inlinedResources: InlinedResources,
  options?: UninlinerOptions,
): DataDocument<{
  readonly [N in keyof InlinedResources]: InlinedResources[N] extends Inlined<
    infer Resource
  >
    ? Resource
    : never;
}>;

export function uninlineResources<Resources extends readonly AnyResource[]>(
  inlinedResources: { readonly [N in keyof Resources]: Inlined<Resources[N]> },
  options?: UninlinerOptions,
): DataDocument<{ readonly [N in keyof Resources]: Resources[N] }>;

export function uninlineResources<Resource extends AnyResource>(
  inlinedResources: readonly Inlined<Resource>[],
  options?: UninlinerOptions,
): DataDocument<readonly Resource[]> {
  const uninliner = new Uninliner(options);

  const data = inlinedResources.map(uninliner.uninline, uninliner);
  const included = uninliner.getIncluded();

  return { data, included, meta: { total: data.length } };
}
