import { IGNORED_ERRORS } from '@studio/utils/ignoredErrors';
import { delay } from '@studio/utils/promise';
import type { RequestContext } from '@studio/utils/requestContext';

import { CLIENT_NAME, DEVICE_INFO, HeaderName, MimeType } from '../constants';
import { HttpError } from '../errors';
import { requestInterceptors, responseInterceptors } from './interceptors';
import { defaultRetryStrategy, type RetryStrategy } from './retryStrategy';
import { getSubdivisionURL } from './subdivision';

type SonicStudioParamsField = 'contentSubsetId';

export interface RequestOptions
  extends Readonly<RequestInit>,
    Omit<Partial<RequestContext>, 'signal'> {
  readonly skipInterceptors?: boolean;
  readonly skipRetries?: boolean;
  readonly skipDeviceInfo?: boolean;
  readonly retryStrategy?: RetryStrategy;
  /** @deprecated should be removed */
  readonly sonicStudioParams?: Readonly<
    Partial<Record<SonicStudioParamsField, string>>
  >;
}

/**
 * Like fetch, but handles errors and sets some headers
 */
export default async function fetchWrapper(
  info: string | URL,
  {
    loginMode = null,
    skipRetries = false,
    skipInterceptors = false,
    skipDeviceInfo = false,
    retryStrategy = defaultRetryStrategy,
    sonicStudioParams,
    subdivision,
    subdivisionStrategies,
    publishingSiteId,
    commerceLineId,
    experience,
    ...init
  }: RequestOptions = {},
): Promise<Response> {
  init.signal?.throwIfAborted();

  let request = new Request(info, {
    // Only allow same-origin requests by default
    mode: 'same-origin',
    // Only send cookies when targeting the same origin
    credentials: 'same-origin',
    ...init,
  });

  if (experience != null || commerceLineId != null) {
    const experienceParams = new URLSearchParams();

    // This is temporary
    if (commerceLineId != null && commerceLineId !== '') {
      experienceParams.set('commerceLine', commerceLineId);
    }

    if (experience != null) {
      for (const key in experience) {
        if (Object.hasOwn(experience, key)) {
          const value = experience[key as keyof typeof experience];
          if (value != null && value !== '') {
            experienceParams.set(key, value);
          }
        }
      }
    }

    const experienceHeader = experienceParams.toString();

    if (experienceHeader !== '') {
      request.headers.set(
        HeaderName.StudioIdentityExperiences,
        experienceHeader,
      );
    }
  }

  // Rewrite the origin of API requests that target the current origin, based on subdivision strategy overrides
  if (subdivision != null) {
    request.headers.set(HeaderName.AdminTenant, subdivision.tenant);
    request.headers.set(HeaderName.AdminMarket, subdivision.market);

    if (subdivisionStrategies != null) {
      const url = getSubdivisionURL(
        new URL(request.url),
        subdivision,
        subdivisionStrategies,
      );
      if (url.href !== request.url) {
        // request.body returns a stream, which is not clonable,
        // so we need to consume the incoming body first
        const bodyBlob = await request.blob();

        // This is unfortunately the only way to create a new request object with a different URL... 😓
        request = new Request(url, {
          body: bodyBlob.size === 0 ? null : bodyBlob,
          // Allow cross-origin requests to use CORS (Cross-Origin Resource Sharing)
          mode: 'cors',
          window: null,
          priority: init.priority ?? 'auto',
          // We can't spread the rest of these, since request is not enumerable
          cache: request.cache,
          credentials: request.credentials,
          headers: request.headers,
          integrity: request.integrity,
          keepalive: request.keepalive,
          method: request.method,
          redirect: request.redirect,
          referrer: request.referrer,
          referrerPolicy: request.referrerPolicy,
          signal: request.signal,
        } satisfies Required<RequestInit>);
      }
    }
  }

  if (sonicStudioParams != null) {
    const value = new URLSearchParams(sonicStudioParams).toString();
    if (value !== '') {
      request.headers.set(HeaderName.SonicStudioParams, value);
    }
  }

  if (publishingSiteId != null) {
    request.headers.set(HeaderName.PublishingSiteID, publishingSiteId);
  }

  request.headers.set(HeaderName.DiscoClient, CLIENT_NAME);

  if (!skipDeviceInfo) {
    request.headers.set(HeaderName.DeviceInfo, DEVICE_INFO);
  }

  if (!request.headers.has(HeaderName.Accept)) {
    request.headers.set(HeaderName.Accept, MimeType.JSONOrWildcard);
  }

  // Replace the inferred text/plain MIME type with application/json
  // https://fetch.spec.whatwg.org/#body-mixin
  if (request.headers.get(HeaderName.ContentType) === MimeType.Text) {
    request.headers.set(HeaderName.ContentType, MimeType.JSON);
  }

  try {
    let response: Response;

    for (let attempt = 0; ; attempt += 1) {
      if (!skipInterceptors) {
        for (const requestInterceptor of requestInterceptors) {
          request = await requestInterceptor(request, loginMode);
        }
      }

      // eslint-disable-next-line no-restricted-globals
      response = await fetch(
        // We always need to clone the request, since it might be retried
        // in the response interceptor
        request.clone(),
      );

      if (response.ok || skipRetries) {
        break;
      }

      const retryAfterMs = retryStrategy(request, response, attempt);
      if (retryAfterMs === false) {
        break;
      }

      await delay(retryAfterMs, request.signal);
    }

    if (!skipInterceptors) {
      for (const responseInterceptor of responseInterceptors) {
        response = await responseInterceptor(response, request, loginMode);
      }
    }

    const error = await HttpError.fromRequestResponse(request, response);

    if (error !== null) {
      throw error;
    }

    return response;
  } catch (error) {
    if (error != null) {
      // Hide the stack frames inside fetchWrapper
      Error.captureStackTrace?.(error, fetchWrapper);

      if (
        error instanceof HttpError &&
        error.status === 403 &&
        error.responseHeaders.get('Server') === 'awselb/2.0'
      ) {
        const vpnError = new Error(
          'Studio is only accessible on a secure network. Please connect to your VPN and refresh the page.',
          { cause: error },
        );
        IGNORED_ERRORS.add(vpnError);
        throw vpnError;
      }
    }
    throw error;
  }
}
