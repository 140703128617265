import type { Role } from '@studio/api/access';
import type { Branded } from '@studio/utils/types';

import type { Experience } from './experience';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const PERMISSION_GROUP_NAME_SYMBOL: unique symbol;
export type PermissionGroupName = Branded<
  string,
  typeof PERMISSION_GROUP_NAME_SYMBOL
>;

export interface PermissionGroupRole {
  readonly name: Role;
  readonly conditions?: Partial<Experience>;
}

export interface PermissionGroupMetadata {
  readonly displayName: string;
  readonly description: string;
  readonly categories: readonly string[];
  readonly approverGroups: readonly string[];
}

export interface PermissionGroup {
  readonly name: PermissionGroupName;
  readonly conditions: Partial<Experience> | null;
  readonly meta: PermissionGroupMetadata;
  readonly roles: readonly PermissionGroupRole[];
}

export type PermissionGroups = ReadonlyMap<
  PermissionGroupName,
  PermissionGroup
>;

type Context = { readonly [_ in string]?: string | undefined | null };
type ConvertInterfaceToDict<T> = { [K in keyof T]: T[K] };

// A <= B
export function isSubsetOfContext(
  a: Context | null | undefined,
  b: Context | null | undefined,
  keysToCheck?: readonly (keyof Context)[] | null,
): boolean {
  if (a == null) {
    return true;
  }

  for (const key in a) {
    if (
      // A has the key
      Object.hasOwn(a, key) &&
      // the key is allowed
      (keysToCheck == null || keysToCheck.includes(key)) &&
      // A's value is not blank
      (a[key] ?? '') !== '' &&
      // B is null
      (b == null ||
        // ...or B doesn't have the the same key
        !Object.hasOwn(b, key) ||
        // ...or A's and B's values are not equal
        a[key] !== b[key])
    ) {
      // A is not a subset of B
      return false;
    }
  }

  return true;
}

export function isEqualContext(
  contextA: Context | null | undefined,
  contextB: Context | null | undefined,
  keysToCheck?: readonly (keyof Context)[] | null,
): boolean {
  return (
    isSubsetOfContext(contextA, contextB, keysToCheck) &&
    isSubsetOfContext(contextB, contextA, keysToCheck)
  );
}

export function resolveRoles(
  experience: ConvertInterfaceToDict<Experience>,
  legacyRoles: Iterable<Role>,
  permissionGroupNames: Iterable<PermissionGroupName>,
  permissionGroups: PermissionGroups,
  keysToValidate?: readonly (keyof Experience)[] | null,
): ReadonlySet<Role> {
  const resolvedRoles = new Set<Role>();

  // Treat the legacy roles as having no conditions
  // TODO: only apply them if context.tenant == beam?
  for (const legacyRole of legacyRoles) {
    resolvedRoles.add(legacyRole);
  }

  for (const groupName of permissionGroupNames) {
    const group = permissionGroups.get(groupName);
    if (group != null) {
      for (const role of group.roles) {
        if (isSubsetOfContext(role.conditions, experience, keysToValidate)) {
          resolvedRoles.add(role.name);
        }
      }
    }
  }

  return resolvedRoles;
}
